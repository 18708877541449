import { useEffect, useState } from "react";
import { Token, URL_API } from "../../utils/Constant";
import axios from "axios";
import { toast } from "react-toastify";

interface AboutInterface {
    [key: string]: any;
}

const useAboutListe = (refresh: boolean): AboutInterface[] => {
    const [about, setAbout] = useState<AboutInterface[]>([]);

    useEffect(() => {
        const fetchAbout = async () => {
            axios.get(`${URL_API}/about-index`, {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            }).then(response => {

                if (response.status == 200) {
                    setAbout(response.data.about);
                }

            }).catch(error => {
                if (error.status === 400) {
                    toast.info(error.data.message);
                }

                if (error.status === 500) {
                    toast.warning(error.data.message);
                }
            })
        };

        fetchAbout();
    }, [refresh]);
    return about;
};

export default useAboutListe;

export const deleteAbout = async ({ id }: { id: number }, onSuccess: () => void) => {

    axios.get(`${URL_API}/about-destroy/${id}`, {
        headers: {
            Authorization: `Bearer ${Token}`,
        }
    }).then(response => {

        if (response.status === 200) {
            toast.info(response.data.message)
            onSuccess();
        }

    }).catch(error => {
        if (error.status === 405) {
            toast.info(error.response.data.message)
        }
    })

}

export const changeStatus = ({ id }: { id: number }, onSuccess: () => void) => {
    axios.get(`${URL_API}/about-ChangeStatus/${id}`, {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    }).then(response => {

        if (response.status === 200) {
            toast.info(response.data.message)
            onSuccess();
        }

    }).catch(error => {
        if (error.status === 405) {
            toast.info(error.response.data.message)
        }
    })
}

