import GestAbout from "../Pages/about";
import GestAutres from "../Pages/Autres";
import GetCategories from "../Pages/Categories/Index";
import GetColors from "../Pages/Couleurs";
import GestItemsAutres from "../Pages/ItemsAutres";
import GetOrders from "../Pages/Orders/Index";
import ViewOrder from "../Pages/Orders/ViewOrder";
import AddProductContainer from "../Pages/ProductList/AddProduct/AddProduct";
import ProductListContainer from "../Pages/ProductList/ProductList";
import Products from "../Pages/Products";
import SamplePage from "../Pages/SamplePage/SamplePage";
import GestSlider from "../Pages/Slider";
import GetSizes from "../Pages/Tailles/Index";
import GestTestimoniales from "../Pages/Testimoniales";
import GestUsers from "../Pages/Users";

const routes = [
  // Sample Page
  { path: `${process.env.PUBLIC_URL}/pages/samplepage`, Component: <SamplePage /> },
  { path: `${process.env.PUBLIC_URL}/pages/produits`, Component: <ProductListContainer /> },
  { path: `${process.env.PUBLIC_URL}/pages/orders`, Component: <GetOrders /> },
  { path: `${process.env.PUBLIC_URL}/pages/Add-Product`, Component: <AddProductContainer /> },
  { path: `${process.env.PUBLIC_URL}/pages/Update-Product/:id`, Component: <AddProductContainer /> },
  { path: `${process.env.PUBLIC_URL}/pages/view-order/:id`, Component: <ViewOrder /> },
  { path: `${process.env.PUBLIC_URL}/pages/sizes`, Component: <GetSizes /> },
  { path: `${process.env.PUBLIC_URL}/pages/colors`, Component: <GetColors /> },
  { path: `${process.env.PUBLIC_URL}/pages/categories`, Component: <GetCategories /> },
  { path: `${process.env.PUBLIC_URL}/pages/utilisateurs`, Component: <GestUsers /> },
  { path: `${process.env.PUBLIC_URL}/autres/menus`, Component: <GestAutres /> },
  { path: `${process.env.PUBLIC_URL}/autres-items`, Component: <GestItemsAutres /> },
  { path: `${process.env.PUBLIC_URL}/testimoniale`, Component: <GestTestimoniales /> },
  { path: `${process.env.PUBLIC_URL}/about`, Component: <GestAbout /> },
  { path: `${process.env.PUBLIC_URL}/slider`, Component: <GestSlider /> },
]

export default routes;