import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Btn, H4, Image, P } from "../../AbstractElements";
import { SearchTableButton, Token, URL_API } from "../../utils/Constant";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SweetAlert from 'sweetalert2';
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Pagination, truncateText } from "../../utils/helper/helpers";
import useAutresListe, { changeStatus, deleteUsers, } from "./AutresApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

type Autres = { [key: string]: any }

const GestAutres = () => {

    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState<Autres[]>([]);
    const [refresh, setRefresh] = useState(false); // État de rafraîchissement
    const [show, setShow] = useState(false); // État de rafraîchissement
    const autres = useAutresListe(refresh) || [];
    const [data, setData] = useState({
        title: '',
        description: '',
        id: null
    });
    const [erreur, setErreur] = useState({
        title: '',
        description: '',
        id: null
    });

    // Gestion du filtrage des utilisateurs
    useEffect(() => {
        const filtered = autres.filter((item) =>
            (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.description && item.description.toLowerCase().includes(filterText.toLowerCase()))
        );
        setFilteredItems(filtered);
    }, [autres, filterText]);


    const subHeaderComponentMemo = useMemo(() => (
        <Row>
            <Col sm="6">
                <div className="dataTables_filter d-flex align-items-center">
                    <Label className="me-2">{SearchTableButton}:</Label>
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                        type="search"
                        value={filterText}
                    />
                </div>
            </Col>
        </Row>
    ), [filterText]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCKEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setData((prevState) => ({
            ...prevState,
            description: data
        }));
    };

    const handleSubmitData = (e: React.FormEvent) => {
        e.preventDefault();
        const url = data.id !== null ? `${URL_API}/autres-update` : `${URL_API}/autres-store`

        axios.post(url, { ...data }, {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        }).then(response => {

            if (response.data.status === 200) {
                const msg = data.id !== null ? "Modification éffectuée avec succès !!" : "Ajout éffectué avec succès !!";
                toast.success(msg);
                clearDataForm();
            }

            // console.log(response)
            if (response.data.status == 401) {
                setErreur(response.data.message);
            }
            if (response.data.status == 400) {
                setErreur(response.data.errors);
            }

        }).catch(error => {
            console.error(error.data);
            toast.info(error.response.data.message);

        })
        setRefresh((prev) => !prev);

    };

    const Confirmation = ({ id, type }: { id: number, type: string }) => {
        SweetAlert.fire({
            title: type === 'supprimer' ? "Suppression" : "Changer de status",
            text: type === 'supprimer' ? "Voulez-vous vraiment supprimer cet utilisateur ?" : "Voulez-vous changer le statut de cet utilisateur ?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: type === 'supprimer' ? 'Oui, Supprimer !' : 'Oui, Changer',
        }).then((result) => {
            if (result.isConfirmed) {
                type == 'supprimer' ? deleteUsers({ id: id }, () => setRefresh(!refresh)) : changeStatus({ id: id }, () => setRefresh(!refresh));
            }
        });
    };

    const clearDataForm = () => {
        setData({
            title: '',
            description: '',
            id: null
        });
        setErreur({
            title: '',
            description: '',
            id: null
        });
        setShow(false)
    };

    const editeData = ({ id }: { id: number }) => {

        const autre = autres.find((item) => item.id === id);

        if (autre) {
            setData({
                description: autre.description,
                title: autre.title,
                id: autre.id ?? null,
            });
            setShow(true)
        } else {
            toast.info('Cet element de figure pas dans nos données')
        }

    }

    return (
        <div className='page-body'>
            <Breadcrumbs mainTitle="Autres" parent="Gest.Menus Autres" />
            <Container fluid>
                <Row>
                    <Col lg="7">
                        <Card>
                            {/* <CardBody>{subHeaderComponentMemo}</CardBody> */}
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <div className="dataTables_filter d-flex align-items-center">
                                            <Label className="me-2">{SearchTableButton}:</Label>
                                            <Input
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                                                type="search"
                                                value={filterText}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <div className={`theme-scrollbar table table-striped bg-primary text-center`}>
                                <Table striped={true} hover={true} size={'sm'} responsive={"sm"}>
                                    <thead className={`tbl-strip-thad-bdr`}>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Titres</th>
                                            <th scope="col">Descriptions</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>{filteredItems.map((item, index) => (
                                        <tr key={item.id} className='text-center'>
                                            <td>
                                                <span className="fw-bold text-dark">
                                                    #FEF00{index + 1}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span className="fw-bold text-dark">
                                                    {item.title}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span className="text-dark" dangerouslySetInnerHTML={{ __html: truncateText(item.description, 50) }}>
                                                </span>
                                            </td>

                                            <td>
                                                {/* {item.status === 'activer' ? (
                                                    <Button className="btn btn-xs btn-success" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Actif
                                                    </Button>
                                                ) : (
                                                    <Button className="btn btn-xs btn-warning" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Inactif
                                                    </Button>
                                                )} */}
                                                <Btn className='bg-info btn-xs mx-1 my-1' onClick={() => editeData({ id: item.id })}>
                                                    <i className='fa fa-pencil'></i>
                                                </Btn>
                                                <Btn className='bg-danger btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id, type: 'supprimer' })}>
                                                    <i className='fa fa-trash'></i>
                                                </Btn>
                                            </td>
                                        </tr>
                                    ))}</tbody>
                                </Table>
                            </div>
                        </Card>
                    </Col>
                    <Col lg="5">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmitData}>
                                    <H4 className="my-4">
                                        {
                                            data.id == null ? ("Enégistrement") : ("Modification")
                                        }
                                    </H4>

                                    <div className="product-info">
                                        <div className="product-group">
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Titre</Label>
                                                        <Input
                                                            placeholder="Entrer le nom"
                                                            name="title"
                                                            value={data.title}
                                                            onChange={changeHandler}
                                                            type="text"
                                                            disabled
                                                        />
                                                        {erreur.title && (
                                                            <span className="text-danger">{erreur.title}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    {
                                                        show === false ? (
                                                            <FormGroup>
                                                                <Label>Descriptions</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={data.description}
                                                                    name='desc'
                                                                    onChange={handleCKEditorChange}
                                                                />
                                                                {erreur.description && (
                                                                    <span className="text-danger">{erreur.description}</span>
                                                                )}
                                                            </FormGroup>
                                                        ) : (
                                                            <div className="text-dark" dangerouslySetInnerHTML={{ __html: data.description }} onClick={() => setShow(!show)}>
                                                            </div>
                                                        )
                                                    }

                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                    <div className="text-end mt-3">
                                        {
                                            data.id === null ? (
                                                null
                                            ) : (
                                                <>
                                                    <Btn color="primary" type="submit">
                                                        Sauvegarder
                                                    </Btn>
                                                    <Btn className="btn btn-danger ms-3" onClick={() => clearDataForm()}>
                                                        Annuler
                                                    </Btn>
                                                </>
                                            )
                                        }


                                    </div>
                                </Form>
                            </CardBody>

                        </Card>

                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default GestAutres