import { invoiceHeaderData } from "../../Data/Application/Ecommerce/Invoice";

const TableHeader = () => {

  return (
    <>
      <th style={{ padding: "18px 15px", textAlign: "left" }}>
        <span style={{ color: "#fff", fontSize: 16, fontWeight: "bold" }}>Produits</span>
      </th>

      <th style={{ padding: "18px 15px", textAlign: "center" }}>
        <span style={{ color: "#fff", fontSize: 16, fontWeight: "bold" }}>Prix</span>
      </th>
      <th style={{ padding: "18px 15px", textAlign: "center" }}>
        <span style={{ color: "#fff", fontSize: 16, fontWeight: "bold" }}>Quantité</span>
      </th>
      {/* <th style={{ padding: "18px 15px", textAlign: "center" }}>
        <span style={{ color: "#fff", fontSize: 16 }}>Reduction</span>
      </th> */}
      <th style={{ padding: "18px 15px", textAlign: "center" }}>
        <span style={{ color: "#fff", fontSize: 16, fontWeight: "bold" }}>Total</span>
      </th>
    </>
  );
};

export default TableHeader;
