import { useEffect, useState } from "react";
import axios from "axios";
import { URL_API } from "../../utils/Constant";
import { toast } from "react-toastify";
import { ColorInterface } from './InterfaceCategories';

const useCategorie = (refresh: boolean): ColorInterface[] => {
    const [cate, setCate] = useState<ColorInterface[]>([]);

    useEffect(() => {
        const fetchCategorie = async () => {
            try {
                const resp = await axios.get(`${URL_API}/admin/categories`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
                    },
                });
                setCate(resp.data.cate);
            } catch (error) {
                toast.warning('Oups !! Une erreur de recuperation des données');
            }
        };

        fetchCategorie();
    }, [refresh]);
    return cate;
};

export default useCategorie;

// export const DeleteCategories = async ({ id }: { id: number }, onSuccess: () => void) => {
//     try {
//         const resp = await axios.get(`${URL_API}/admin/delete-colors/${id}`, {
//             headers: {
//                 Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`
//             }
//         })

//         if (resp.data.status === 200) {
//             toast.warning(resp.data.message)
//             onSuccess();
//         }

//         if (resp.data.status == 401) {
//             toast.info(resp.data.message)
//         }

//     } catch (error) {
//         toast.info('Oups !! Une erreur de modification de données')
//     }

// }

// export const ChangeStatus = async ({ id }: { id: number }, onSuccess: () => void) => {
//     try {
//         const resp = await axios.get(`${URL_API}/admin/change-status-colors/${id}`, {
//             headers: {
//                 Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`
//             }
//         })

//         if (resp.data.status === 200) {
//             toast.info(resp.data.message)
//             onSuccess();
//         }

//         if (resp.data.status == 401) {
//             toast.info(resp.data.message)
//         }

//     } catch (error) {
//         toast.info('Oups !! Une erreur de modification de données')
//     }
// }