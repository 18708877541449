import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Btn, H4, Image, P } from "../../AbstractElements";
import { SearchTableButton, Token, URL_API } from "../../utils/Constant";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SweetAlert from 'sweetalert2';
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Pagination, truncateText } from "../../utils/helper/helpers";
import useSliderList, { changeStatus, deleteSliders, } from "./api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { dynamicImage } from "../../Service";

type Sliders = { [key: string]: any }
type DataState = {
    title: string;
    image: File | null;
    urlImage: string | null;
    id: string | null;
};

const GestSlider = () => {

    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState<Sliders[]>([]);
    const [refresh, setRefresh] = useState(false);// État de rafraîchissement
    const sliders = useSliderList(refresh) || [];
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [data, setData] = useState<DataState>({
        title: '',
        image: null,
        urlImage: null,
        id: null
    });
    const [erreur, setErreur] = useState({
        title: '',
        urlImage: null,
        image: null,
        id: null
    });

    // Gestion du filtrage des utilisateurs
    useEffect(() => {
        setFilteredItems(sliders.filter((item) =>
            (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.menusTitle && item.menusTitle.toLowerCase().includes(filterText.toLowerCase()))
        ));
    }, [sliders, filterText, refresh]); // Ajoute refresh ici


    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmitData = async (e: React.FormEvent) => {
        e.preventDefault();
        const url = data.id !== null ? `${URL_API}/slider-update` : `${URL_API}/slider-store`;
        // Rafraichissement slider
        try {
            const response = await axios.post(url, { ...data }, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.status === 200) {
                clearDataForm();
                const msg = data.id !== null ? "Modification effectuée avec succès !!" : "Ajout effectué avec succès !!";
                toast.success(msg);
                setRefresh(prev => !prev);
            }
        } catch (error: any) {
            if (error.response.status === 401 || error.response.status === 400) {
                setErreur(error.response.data.errors || error.response.data.message);
                toast.info(error.response.data.message);
            }
        }

    };

    const Confirmation = ({ id, type }: { id: number, type: string }) => {
        SweetAlert.fire({
            title: type === 'supprimer' ? "Suppression" : "Changer de status",
            text: type === 'supprimer' ? "Voulez-vous vraiment supprimer cet élément ?" : "Voulez-vous changer le statut de cet élément ?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: type === 'supprimer' ? 'Oui, Supprimer !' : 'Oui, Changer',
        }).then((result) => {
            if (result.isConfirmed) {
                type == 'supprimer' ? deleteSliders({ id: id }, () => setRefresh(!refresh)) : changeStatus({ id: id }, () => setRefresh(!refresh));
            }
        });
    };

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        if (file && file.type.startsWith("image/") && file.size <= 5000000) { // Limite de 5 Mo
            setData((prevData) => ({
                ...prevData,
                image: file,
            }));

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("Le fichier doit être une image et de taille inférieure à 5 Mo");
        }
    };

    const clearDataForm = () => {
        setData({
            title: '',
            image: dynamicImage('search-not-found.png'),
            urlImage: null,
            id: null
        });
        setImagePreview(null);
        setErreur({
            title: '',
            image: null,
            urlImage: null,
            id: null
        });
    };

    const editeData = ({ id }: { id: number }) => {

        const SlidersItems = sliders.find((item) => item.id === id);

        if (SlidersItems) {
            setData({
                title: SlidersItems.title,
                id: SlidersItems.id ?? null,
                image: null,
                urlImage: SlidersItems.images ?? null,
            });
        } else {
            toast.info('Cet element de figure pas dans nos données')
        }

    }

    return (
        <div className='page-body'>
            <Breadcrumbs mainTitle="Utilisateur" parent="Gest.Utilisateur" />
            <Container fluid>
                <Row>
                    <Col lg="7">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="4">
                                        <div className="dataTables_filter d-flex align-items-center">
                                            <Label className="me-2">{SearchTableButton}:</Label>
                                            <Input
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                                                type="search"
                                                value={filterText}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <div className={`theme-scrollbar table table-striped bg-primary text-center`}>
                                <Table striped={true} hover={true} size={'sm'} responsive={"sm"}>
                                    <thead className={`tbl-strip-thad-bdr`}>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Images</th>
                                            <th scope="col">Titres</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>{filteredItems.map((item, index) => (
                                        <tr key={item.id} className='text-center'>
                                            <td>
                                                <span className="fw-bold text-dark">
                                                    #FEF00{index + 1}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <img src={item.images} alt="Image produit" width={50} height={50} />
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <span className="fw-bold text-dark">
                                                    {item.title}
                                                </span>
                                            </td>

                                            <td>
                                                {item.status === 'activer' ? (
                                                    <Button className="btn btn-xs btn-success" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Actif
                                                    </Button>
                                                ) : (
                                                    <Button className="btn btn-xs btn-warning" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Inactif
                                                    </Button>
                                                )}
                                                <Btn className='bg-info btn-xs mx-1 my-1' onClick={() => editeData({ id: item.id })}>
                                                    <i className='fa fa-pencil'></i>
                                                </Btn>
                                                <Btn className='bg-danger btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id, type: 'supprimer' })}>
                                                    <i className='fa fa-trash'></i>
                                                </Btn>
                                            </td>
                                        </tr>
                                    ))}</tbody>
                                </Table>
                            </div>
                        </Card>
                    </Col>
                    <Col lg="5">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmitData}>
                                    <H4 className="my-4">
                                        {
                                            data.id == null ? ("Enrégistrement") : ("Modification")
                                        }
                                    </H4>

                                    <div className="product-info">
                                        <div className="product-group">
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Image</Label>
                                                        <div className="text-center">
                                                            <img
                                                                src={imagePreview ? imagePreview : data.urlImage ? data.urlImage : data.image ? data.image : dynamicImage('search-not-found.png')}
                                                                alt="Preview"
                                                                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                                            />
                                                        </div>
                                                        <Input type="file" name="image" accept="image/*" onChange={handleImageChange} />
                                                        {erreur.image && (
                                                            <span className="text-danger">{erreur.image}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col sm="6">
                                                    <FormGroup>
                                                        <Label>Vidéo</Label>
                                                        {videoPreview ? (
                                                            <div className="text-center p-2 pb-2">
                                                                <video className="d-block w-100" controls>
                                                                    <source src={videoPreview} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        ) : (
                                                            <div className="text-center">
                                                                <img
                                                                    src={dynamicImage('search-not-found.png')}
                                                                    alt="Preview"
                                                                    style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                                                />
                                                            </div>
                                                        )}
                                                        <Input
                                                            type="file"
                                                            name="video"
                                                            accept="video/*"
                                                            onChange={handleFileChange}
                                                        />
                                                        {erreur.video && (
                                                            <span className="text-danger">{erreur.video}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col> */}
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Titre</Label>
                                                        <Input
                                                            placeholder="Entrer le nom"
                                                            name="title"
                                                            value={data.title}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {erreur.title && (
                                                            <span className="text-danger">{erreur.title}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>

                                                {/* <Col sm="12">
                                                    {
                                                        show === false ? (
                                                            <FormGroup>
                                                                <Label>Descriptions</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={data.description}
                                                                    name='desc'
                                                                    onChange={handleCKEditorChange}
                                                                />
                                                                {erreur.description && (
                                                                    <span className="text-danger">{erreur.description}</span>
                                                                )}
                                                            </FormGroup>
                                                        ) : (
                                                            <div className="text-dark" dangerouslySetInnerHTML={{ __html: data.description }} onClick={() => setShow(!show)}>
                                                            </div>
                                                        )
                                                    }

                                                </Col> */}
                                            </Row>

                                        </div>
                                    </div>
                                    <div className="text-end mt-3">
                                        <Btn color="primary" type="submit">
                                            {data.id == null ? "Ajouter" : "Sauvegarder"}
                                        </Btn>
                                        <Btn className="btn btn-danger ms-3" onClick={() => clearDataForm()}>
                                            Annuler
                                        </Btn>
                                    </div>
                                </Form>
                            </CardBody>

                        </Card>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GestSlider