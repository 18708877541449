import { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs"
import useColorList, { ChangeStatus, DeleteColors } from "./ApiColors";
import { CommonTableProp } from "../../Type/Tables/ReactstrapTable/BasicTable/BasicTable";
import SweetAlert from 'sweetalert2';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Btn, H4 } from "../../AbstractElements";
import { SearchTableButton, URL_API } from "../../utils/Constant";
import { toast } from "react-toastify";
import axios from "axios";

type Colors = { [key: string]: any }

const GetColors = () => {
    const [filterText, setFilterText] = useState("");
    const [refresh, setRefresh] = useState(false); // État de rafraîchissement
    const color = useColorList(refresh) || []; // Passer l'état de rafraîchissement comme paramètre
    const [filteredItems, setFilteredItems] = useState<Colors[]>([]);
    const [forms, setForms] = useState<Colors>({
        name: '',
        code: '',
        id: null
    });
    const [errorValidate, setErrorValidate] = useState({
        name: '',
        code: '',
        id: null
    });

    useEffect(() => {
        const filtered = color.filter((item) =>
            item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
        );
        if (JSON.stringify(filtered) !== JSON.stringify(filteredItems)) {
            setFilteredItems(filtered);
        }
    }, [color, filterText, filteredItems]);

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Row>
                <Col sm="6">
                    <div className="dataTables_filter d-flex align-items-center">
                        <Label className="me-2">{SearchTableButton}:</Label>
                        <Input
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                            type="search"
                            value={filterText}
                        />
                    </div>
                </Col>
            </Row>
        );
    }, [filterText]);

    const customColorHoverHead = [
        { id: 1, head: 'Id' },
        { id: 2, head: 'Color' },
        { id: 3, head: 'Name' },
        { id: 4, head: 'Status' },
        { id: 5, head: 'Actions' },
    ];

    const CommonTable: React.FC<CommonTableProp> = ({ tableClass, strip, size, hover, headClass, headData, children }) => {
        return (
            <div className={`theme-scrollbar ${tableClass ? tableClass : ""}`}>
                <Table striped={strip} hover={hover} size={size} responsive={"sm"}>
                    <thead className={headClass}>
                        <tr>
                            {headData.map((head) => (
                                <th key={head.id} scope="col">
                                    {head.head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{children}</tbody>
                </Table>
            </div>
        );
    };

    const Confirmation = ({ id, type }: { id: number, type: string }) => {
        SweetAlert.fire({
            title: 'Êtes-vous sûr?',
            text: type == 'supprimer' ? "De Vouloir Supprimer cette couleur ??" : "De vouloir changer le status de cet element",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: type == 'supprimer' ? 'Oui, Supprimer !' : 'Oui, Changer'
        }).then((result) => {
            if (result.isConfirmed) {
                type == 'supprimer' ? DeleteColors({ id: id }, () => setRefresh(!refresh)) : ChangeStatus({ id: id }, () => setRefresh(!refresh)); // Passer une fonction de rappel pour mettre à jour l'état de rafraîchissement
            }
        });
    };

    const clearDataForm = () => {
        setForms({
            name: '',
            code: '',
            id: null
        });

        setErrorValidate({
            name: '',
            code: '',
            id: null
        })
    }

    const handleSubmitData = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const url = forms.id ? `${URL_API}/admin/update-colors` : `${URL_API}/admin/store-colors`;

            const response = await axios.post(url, forms, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
                },
            });

            if (response.data.status === 400) {
                setErrorValidate(response.data.errors);
                toast.error(response.data.message);
            }

            if (response.data.status === 200) {
                toast.success(response.data.message);
                clearDataForm();
            }

            if (response.data.status === 401) {
                toast.info(response.data.message)
            }
            setRefresh((prev) => !prev);

        } catch (error) {
            toast.error('Erreur lors de l\'ajout de la taille');
        }
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, type, value } = e.target;
        setForms({ ...forms, [name]: value, });
    };

    const editeColor = ({ id }: { id: number }) => {
        const color = filteredItems.find((item) => item.id === id);
        color ? setForms({
            name: color.name,
            code: color.color,
            id: color.id
        }) : toast.info('Cet element en figure pas dans le donnes')
    }

    return (
        <div className="page-body">
            <Breadcrumbs mainTitle='Tailles' parent='Accueil' />
            <Container fluid>
                <Row>
                    <Col sm="6">
                        <Card>
                            <CardBody>{subHeaderComponentMemo}</CardBody>
                            <CommonTable
                                strip
                                hover
                                tableClass='table table-striped bg-primary text-center'
                                headClass='tbl-strip-thad-bdr'
                                headData={customColorHoverHead}
                            >
                                {filteredItems.map((item, index) => (
                                    <tr key={item.id} className='text-center'>
                                        <th scope='row'>#{index + 1}</th>
                                        <td style={{ textAlign: "right" }}>
                                            <Btn className='btn-xs mx-1 my-1 p-2' style={{ backgroundColor: item.color }}>
                                            </Btn>
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <span className="fw-bold text-dark">
                                                {item.name}
                                            </span>
                                        </td>
                                        <td>
                                            {item.status === 'activer' ? (
                                                <Button className="btn btn-xs btn-success" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                    Activer
                                                </Button>
                                            ) : (
                                                <Button className="btn btn-xs btn-warning" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                    Desactiver
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <Btn className='bg-info btn-xs mx-1 my-1' onClick={() => editeColor({ id: item.id })}>
                                                <i className='fa fa-pencil'></i>
                                            </Btn>
                                            <Btn className='bg-danger btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id, type: 'supprimer' })}>
                                                <i className='fa fa-trash'></i>
                                            </Btn>
                                        </td>
                                    </tr>
                                ))}
                            </CommonTable>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmitData}>
                                    <H4 className="my-4">
                                        {
                                            forms.id == null ? ("Ajouter une Couleur d'arcticle") : ("Modification d'une couleur d'article")
                                        }
                                    </H4>

                                    <div className="product-info">
                                        <div className="product-group">
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Couleur</Label>
                                                        <Input
                                                            placeholder="Entrer la couleur d'article"
                                                            name="name"
                                                            value={forms.name}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {errorValidate.name && (
                                                            <span className="text-danger">{errorValidate.name}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Code Couleur</Label>
                                                        <Input
                                                            placeholder="Entrer le code couleur"
                                                            name="code"
                                                            value={forms.code}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {errorValidate.code && (
                                                            <span className="text-danger">{errorValidate.code}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <Btn color="primary" type="submit">
                                            {forms.id == null ? "Ajouter" : "Sauvegarder"}
                                        </Btn>
                                        <Btn className="btn btn-danger ms-3" onClick={() => clearDataForm()}>
                                            Annuler
                                        </Btn>
                                    </div>
                                </Form>
                            </CardBody>

                        </Card>

                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default GetColors