import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Btn, H4, Image, P } from "../../AbstractElements";
import { SearchTableButton, Token, URL_API } from "../../utils/Constant";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SweetAlert from 'sweetalert2';
import { useEffect, useMemo, useState } from "react";
import useUsersListe, { changeStatus, deleteUsers, resetPassword } from "./UserApi";
import { toast } from "react-toastify";
import axios from "axios";
import { formatPhoneNumber, Pagination, truncateText } from "../../utils/helper/helpers";

type Users = { [key: string]: any }

const GestUsers = () => {

    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState<Users[]>([]);
    const [refresh, setRefresh] = useState(false); // État de rafraîchissement
    const users = useUsersListe(refresh) || [];
    // const roles = useRolesList(refresh) || [];
    const [data, setData] = useState({
        last_name: '',
        first_name: '',
        email: '',
        telephone: '',
        adresse: '',
        id: null
    });
    const [erreur, setErreur] = useState({
        last_name: '',
        first_name: '',
        email: '',
        telephone: '',
        adresse: '',
        id: null
    });

    // Gestion du filtrage des utilisateurs
    useEffect(() => {
        const filtered = users.filter((item) =>
            (item.last_name && item.last_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase()))
        );
        setFilteredItems(filtered);
    }, [users, filterText]);


    const subHeaderComponentMemo = useMemo(() => (
        <Row>
            <Col sm="6">
                <div className="dataTables_filter d-flex align-items-center">
                    <Label className="me-2">{SearchTableButton}:</Label>
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                        type="search"
                        value={filterText}
                    />
                </div>
            </Col>
        </Row>
    ), [filterText]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, type, value } = e.target;

        // if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
        //     const checked = e.target.checked; // On accède à 'checked' uniquement si c'est un 'input' de type 'checkbox'
        //     const roleId = parseInt(value);
        //     setData(prev => ({
        //         ...prev,
        //         role: checked ? [...prev.role, roleId] : prev.role.filter(id => id !== roleId)
        //     }));
        // } else {
        setData({ ...data, [name]: value });
        // }

    };

    const handleSubmitData = (e: React.FormEvent) => {
        e.preventDefault();
        // Validation et soumission des données
        const url = data.id !== null ? `${URL_API}/update-infos` : `${URL_API}/create-users`

        axios.post(url, { ...data }, {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        }).then(response => {

            if (response.data.status === 200) {
                const msg = data.id !== null ? "Utilisateur modifié avec succès !!" : "Utilisateur ajouté avec succès !!";
                localStorage.setItem('user', JSON.stringify(response.data.user));
                toast.success(msg);
                clearDataForm();
            }


        }).catch(error => {
            // console.error(error.data);
            toast.info(error.response.data.message);
            if (error.response.data.status == 401) {
                setErreur(error.response.data.message);
            }
            if (error.response.data.status == 400) {
                setErreur(error.response.data.message);
            }
        })
        setRefresh((prev) => !prev);

    };

    const Confirmation = ({ id, type }: { id: number, type: string }) => {
        SweetAlert.fire({
            title: type === 'supprimer' ? "Suppression" : type === 'Reset' ? "Mot de passe" : "Changer de status",
            text: type === 'supprimer' ? "Voulez-vous vraiment supprimer cet utilisateur ?" : type === 'Reset' ? "Voulez-vous reinitialiser le mot de passe de cet utilisateur" : "Voulez-vous changer le statut de cet utilisateur ?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: type === 'supprimer' ? 'Oui, Supprimer !' : type === 'Reset' ? "Oui, Reinitialiser" : 'Oui, Changer',
        }).then((result) => {
            if (result.isConfirmed) {
                type == 'supprimer' ? deleteUsers({ id: id }, () => setRefresh(!refresh)) : type === 'Reset' ? resetPassword({ id: id }, () => setRefresh(!refresh)) : changeStatus({ id: id }, () => setRefresh(!refresh));
            }
        });
    };

    const clearDataForm = () => {
        setData({
            last_name: '',
            first_name: '',
            email: '',
            telephone: '',
            adresse: '',
            id: null
        });
        setErreur({
            last_name: '',
            first_name: '',
            email: '',
            telephone: '',
            adresse: '',
            id: null
        });
    };

    const editeData = ({ id }: { id: number }) => {

        const user = users.find((item) => item.id === id);

        if (user) {
            setData({
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                telephone: user.phone,
                adresse: user.adresse,
                id: user.id ?? null,
            });
        } else {
            toast.info('Cet element de figure pas dans nos données')
        }

    }

    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 10; // Nombre de produits par page

    // // Calculer l'index des produits à afficher pour la page courante
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentProducts = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // // Calculer le nombre total de pages
    // const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    // // Fonction pour changer de page
    // const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    // console.log(filteredItems)
    return (
        <div className='page-body'>
            <Breadcrumbs mainTitle="Utilisateur" parent="Gest.Utilisateur" />
            <Container fluid>
                <Row>
                    <Col lg="8">
                        <Card>
                            {/* <CardBody>{subHeaderComponentMemo}</CardBody> */}
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <div className="dataTables_filter d-flex align-items-center">
                                            <Label className="me-2">{SearchTableButton}:</Label>
                                            <Input
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                                                type="search"
                                                value={filterText}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <div className={`theme-scrollbar table table-striped bg-primary text-center`}>
                                <Table striped={true} hover={true} size={'sm'} responsive={"sm"}>
                                    <thead className={`tbl-strip-thad-bdr`}>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Nom & Prénom</th>
                                            <th scope="col">Téléphone & Email</th>
                                            <th scope="col">Adresse</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>{filteredItems.map((item, index) => (
                                        <tr key={item.id} className='text-center'>
                                            <td>
                                                <span className="fw-bold text-dark">
                                                    #FEF00{index + 1}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span className="fw-bold text-dark">
                                                    {item.last_name + " " + item.first_name}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span className="fw-bold text-white">
                                                    {item.email}
                                                </span><br />
                                                <span className="fw-bold text-dark">
                                                    {formatPhoneNumber(item.phone)}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span className="fw-bold text-dark">
                                                    {truncateText(item.adresse, 10)}
                                                </span>
                                            </td>

                                            <td>
                                                {item.status === 'activer' ? (
                                                    <Button className="btn btn-xs btn-success" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Actif
                                                    </Button>
                                                ) : (
                                                    <Button className="btn btn-xs btn-warning" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Inactif
                                                    </Button>
                                                )}
                                                <Btn className='bg-info btn-xs mx-1 my-1' onClick={() => editeData({ id: item.id })}>
                                                    <i className='fa fa-pencil'></i>
                                                </Btn>
                                                <Btn className='bg-danger btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id, type: 'supprimer' })}>
                                                    <i className='fa fa-trash'></i>
                                                </Btn>
                                                <Btn className='bg-dark btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id, type: 'Reset' })}>
                                                    <i className='fa fa-unlock'></i>
                                                </Btn>
                                            </td>
                                        </tr>
                                    ))}</tbody>
                                </Table>
                            </div>

                            {/* {
            totalPages > 1 ? <Pagination totalPages={totalPages} paginate={paginate(currentPage)} currentPage={currentPage} /> : null
          } */}

                            {/* </CommonTable> */}
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmitData}>
                                    <H4 className="my-4">
                                        {
                                            data.id == null ? ("Ajouter un nouveau utilisateur") : ("Modification d'un utilisateur")
                                        }
                                    </H4>

                                    <div className="product-info">
                                        <div className="product-group">
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label>Nom</Label>
                                                        <Input
                                                            placeholder="Entrer le nom"
                                                            name="last_name"
                                                            value={data.last_name}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {erreur.last_name && (
                                                            <span className="text-danger">{erreur.last_name}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label>Prénoms</Label>
                                                        <Input
                                                            placeholder="Entrer le prénom"
                                                            name="first_name"
                                                            value={data.first_name}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {erreur.first_name && (
                                                            <span className="text-danger">{erreur.first_name}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label>E-mail</Label>
                                                        <Input
                                                            placeholder="Entrer l'e-mail"
                                                            name="email"
                                                            value={data.email}
                                                            onChange={changeHandler}
                                                            type="email"
                                                        />
                                                        {erreur.email && (
                                                            <span className="text-danger">{erreur.email}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label>Téléphone</Label>

                                                        <Input
                                                            placeholder="Entrer le numéro téléphone"
                                                            name="telephone"
                                                            value={data.telephone}
                                                            onChange={changeHandler}
                                                            type="number"
                                                        />
                                                        {erreur.telephone && (
                                                            <span className="text-danger">{erreur.telephone}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>

                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Adresse</Label>
                                                        <Input
                                                            placeholder="Entrer l'adresse"
                                                            name="adresse"
                                                            value={data.adresse}
                                                            onChange={changeHandler}
                                                            type="textarea"
                                                        />
                                                        {erreur.adresse && (
                                                            <span className="text-danger">{erreur.adresse}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="text-end mt-3">
                                        <Btn color="primary" type="submit">
                                            {data.id == null ? "Ajouter" : "Sauvegarder"}
                                        </Btn>
                                        <Btn className="btn btn-danger ms-3" onClick={() => clearDataForm()}>
                                            Annuler
                                        </Btn>
                                    </div>
                                </Form>
                            </CardBody>

                        </Card>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GestUsers