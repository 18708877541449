import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Btn, H4, Image, P } from "../../AbstractElements";
import { SearchTableButton, Token, URL_API } from "../../utils/Constant";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SweetAlert from 'sweetalert2';
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Pagination, truncateText } from "../../utils/helper/helpers";
import useTestiListe, { changeStatus, deleteTesit, } from "./ApiTestimonial";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { dynamicImage } from "../../Service";

type Testi = { [key: string]: any }
type DataState = {
    fullname: string;
    poste: string;
    image: File | null; // Autoriser à être soit null, soit un fichier
    urlImage: string | null;
    commentaire: string;
    id: string | null;
};

const GestTestimoniales = () => {

    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState<Testi[]>([]);
    const [refresh, setRefresh] = useState(false); // État de rafraîchissement
    const [show, setShow] = useState(false); // État de rafraîchissement
    const testi = useTestiListe(refresh) || [];
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const [data, setData] = useState<DataState>({
        fullname: '',
        poste: '',
        image: null,
        urlImage: null,
        commentaire: '',
        id: null
    });
    const [erreur, setErreur] = useState({
        fullname: '',
        poste: '',
        image: null,
        urlImage: null,
        commentaire: '',
        id: null
    });

    useEffect(() => {
        const filtered = testi.filter((item) =>
            (item.fullname && item.fullname.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.commentaire && item.commentaire.toLowerCase().includes(filterText.toLowerCase()))
        );
        setFilteredItems(filtered);
    }, [testi, filterText]);


    const subHeaderComponentMemo = useMemo(() => (
        <Row>
            <Col sm="6">
                <div className="dataTables_filter d-flex align-items-center">
                    <Label className="me-2">{SearchTableButton}:</Label>
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                        type="search"
                        value={filterText}
                    />
                </div>
            </Col>
        </Row>
    ), [filterText]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCKEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setData((prevState) => ({
            ...prevState,
            commentaire: data
        }));
    };

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        if (file && file.type.startsWith("image/") && file.size <= 5000000) { // Limite de 5 Mo
            setData((prevData) => ({
                ...prevData,
                image: file,
            }));

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("Le fichier doit être une image et de taille inférieure à 5 Mo");
        }
    };

    const handleSubmitData = (e: React.FormEvent) => {
        e.preventDefault();
        const url = data.id !== null ? `${URL_API}/testi-update` : `${URL_API}/testi-store`

        console.log(data);

        axios.post(url, { ...data }, {
            headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'multipart/form-data',

            }
        }).then(response => {

            if (response.status === 200) {
                const msg = data.id !== null ? "Modification éffectuée avec succès !!" : "Ajout éffectué avec succès !!";
                toast.success(msg);
                clearDataForm();
            }


            console.log(response)
        }).catch(error => {
            toast.info(error.response.data.message);
            if (error.response.status == 400) {
                setErreur(error.response.data.errors);
            }

        })
        setRefresh((prev) => !prev);

    };

    const Confirmation = ({ id, type }: { id: number, type: string }) => {
        SweetAlert.fire({
            title: type === 'supprimer' ? "Suppression" : "Changer de status",
            text: type === 'supprimer' ? "Voulez-vous vraiment supprimer cet utilisateur ?" : "Voulez-vous changer le statut de cet utilisateur ?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: type === 'supprimer' ? 'Oui, Supprimer !' : 'Oui, Changer',
        }).then((result) => {
            if (result.isConfirmed) {
                type == 'supprimer' ? deleteTesit({ id: id }, () => setRefresh(!refresh)) : changeStatus({ id: id }, () => setRefresh(!refresh));
            }
        });
    };

    const clearDataForm = () => {
        setData({
            fullname: '',
            poste: '',
            image: null,
            urlImage: null,
            commentaire: '',
            id: null
        });
        setErreur({
            fullname: '',
            poste: '',
            image: null,
            urlImage: null,
            commentaire: '',
            id: null
        });
        setShow(false)
    };

    const editeData = ({ id }: { id: number }) => {

        const testimoniale = testi.find((item) => item.id === id);

        if (testimoniale) {
            setData({
                commentaire: testimoniale.commentaire,
                fullname: testimoniale.fullname,
                id: testimoniale.id ?? null,
                image: null,
                urlImage: testimoniale.img ?? null,
                poste: testimoniale.poste,
            });
            setShow(true)
        } else {
            toast.info('Cet element de figure pas dans nos données')
        }

    }

    return (
        <div className='page-body'>
            <Breadcrumbs mainTitle="Temoignage" parent="Gest.Menus Temoignage" />
            <Container fluid>
                <Row>
                    <Col lg="7">
                        <Card>
                            {/* <CardBody>{subHeaderComponentMemo}</CardBody> */}
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <div className="dataTables_filter d-flex align-items-center">
                                            <Label className="me-2">{SearchTableButton}:</Label>
                                            <Input
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                                                type="search"
                                                value={filterText}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <div className={`theme-scrollbar table table-striped bg-primary text-center`}>
                                <Table striped={true} hover={true} size={'sm'} responsive={"sm"}>
                                    <thead className={`tbl-strip-thad-bdr`}>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Images</th>
                                            <th scope="col">Nom & Prénoms</th>
                                            <th scope="col">Commentaires</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>{filteredItems.map((item, index) => (
                                        <tr key={item.id} className='text-center'>
                                            <td>
                                                <span className="fw-bold text-dark">
                                                    #FEF00{index + 1}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <img src={item.img} alt="Image produit" width={50} height={50} />
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <span className="fw-bold text-dark">
                                                    {item.fullname}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span className="text-dark" dangerouslySetInnerHTML={{ __html: truncateText(item.commentaire, 50) }}>
                                                </span>
                                            </td>

                                            <td>
                                                {/* {item.status === 'activer' ? (
                                                    <Button className="btn btn-xs btn-success" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Actif
                                                    </Button>
                                                ) : (
                                                    <Button className="btn btn-xs btn-warning" onClick={() => Confirmation({ id: item.id, type: "changeStatus" })}>
                                                        Inactif
                                                    </Button>
                                                )} */}
                                                <Btn className='bg-info btn-xs mx-1 my-1' onClick={() => editeData({ id: item.id })}>
                                                    <i className='fa fa-pencil'></i>
                                                </Btn>
                                                <Btn className='bg-danger btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id, type: 'supprimer' })}>
                                                    <i className='fa fa-trash'></i>
                                                </Btn>
                                            </td>
                                        </tr>
                                    ))}</tbody>
                                </Table>
                            </div>
                        </Card>
                    </Col>
                    <Col lg="5">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmitData}>
                                    <H4 className="my-4">
                                        {
                                            data.id == null ? ("Enégistrement") : ("Modification")
                                        }
                                    </H4>

                                    <div className="product-info">
                                        <div className="product-group">
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Image</Label>
                                                        <div className="text-center">
                                                            <img
                                                                src={imagePreview ? imagePreview : data.urlImage ? data.urlImage : data.image ? data.image : dynamicImage('search-not-found.png')}
                                                                // src={imagePreview || forms.image || dynamicImage('search-not-found.png')}
                                                                alt="Preview"
                                                                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                                            />
                                                        </div>
                                                        <Input type="file" name="image" accept="image/*" onChange={handleImageChange} />
                                                        {erreur.image && (
                                                            <span className="text-danger">{erreur.image}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label>Nom et Prénom</Label>
                                                        <Input
                                                            placeholder="Entrer le nom et le prénom"
                                                            name="fullname"
                                                            value={data.fullname}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {erreur.fullname && (
                                                            <span className="text-danger">{erreur.fullname}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label>Poffession ou Poste</Label>
                                                        <Input
                                                            placeholder="Entrer la profession ou le poste"
                                                            name="poste"
                                                            value={data.poste}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {erreur.poste && (
                                                            <span className="text-danger">{erreur.poste}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    {
                                                        show === false ? (
                                                            <FormGroup>
                                                                <Label>commentaires</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={data.commentaire}
                                                                    name='desc'
                                                                    onChange={handleCKEditorChange}
                                                                />
                                                                {erreur.commentaire && (
                                                                    <span className="text-danger">{erreur.commentaire}</span>
                                                                )}
                                                            </FormGroup>
                                                        ) : (
                                                            <div className="text-dark" dangerouslySetInnerHTML={{ __html: data.commentaire }} onClick={() => setShow(!show)}>
                                                            </div>
                                                        )
                                                    }

                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                    <div className="text-end mt-3">
                                        <Btn color="primary" type="submit">
                                            {data.id == null ? "Ajouter" : "Sauvegarder"}
                                        </Btn>
                                        <Btn className="btn btn-danger ms-3" onClick={() => clearDataForm()}>
                                            Annuler
                                        </Btn>
                                    </div>
                                </Form>
                            </CardBody>

                        </Card>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GestTestimoniales