import { Container, Form } from "reactstrap";
import { AddProduct, Check, Quantity, URL_API } from "../../../utils/Constant";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  CategoriesLearning,
  Color,
  Description,
  EnterProductDescription,
  EnterProductName,
  ProductCategory,
  ProductDescription,
  ProductName,
  Quality,
  ProductImageHeading,
  Price,
  SelectSizeHeading,
  Size
} from "../../../utils/Constant";
import { H4, Btn, Alerts, P } from "../../../AbstractElements";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { CategoriesInterface, ColorInterface, SizeInterface } from "../HelperProduct";
import { dynamicImage } from "../../../Service";
import SvgIcon from "../../../CommonElements/SVG/SvgIcon";

// Typage des variables à envoyer en post
type FormsState = {
  pname: string;
  desc: string;
  categorie: string;
  color: string[];
  qualite: string;
  size: string[];
  price: number;
  quantite: number;
  image: File | null;
  urlImage?: string;
  add_offer: boolean;
  offer_number: number;
  offer_date_debut: string;
  offer_date_fin: string;
};

const AddProductContainer = () => {
  // Initialisation des variables à envoyer en post
  const { id } = useParams();
  const navigate = useNavigate();
  const [forms, setForms] = useState<FormsState>({
    pname: '',
    desc: '',
    categorie: '',
    color: [],
    qualite: '',
    size: [],
    price: 0,
    quantite: 0,
    image: null,
    add_offer: false,
    offer_number: 0,
    offer_date_debut: '',
    offer_date_fin: '',
  });

  const [color, setColor] = useState<ColorInterface[]>([]);
  const [size, setSize] = useState<SizeInterface[]>([]);
  const [cate, setCate] = useState<CategoriesInterface[]>([]);
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [errorValidate, setErrorValidate] = useState({
    pname: '',
    desc: '',
    categorie: '',
    color: '',
    qualite: '',
    size: '',
    price: '',
    quantite: '',
    image: null,
    urlImage: null,
    add_offer: false,
    offer_number: '',
    offer_date_debut: '',
    offer_date_fin: '',
  });

  // Fonction de récupération des données
  const fetchProducts = async () => {
    try {
      const res = await axios.get(`${URL_API}/admin/add-products`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
        },
      });
      setColor(res.data.colors);
      setSize(res.data.sizes);
      setCate(res.data.categories);
    } catch (error) {
      toast.warning('Oups !! Une erreur de récupération des données');
    }
  };

  const fetchUpdateProduct = async () => {
    try {
      const res = await axios.get(`${URL_API}/admin/edite-products/${id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
        },
      });

      const preloadedData = res.data.product;

      setForms({
        pname: preloadedData.title || '',
        desc: preloadedData.description || '',
        categorie: preloadedData.category_id || '',
        color: preloadedData.colors ? preloadedData.colors.split(',') : [],
        qualite: preloadedData.qualite || '',
        size: preloadedData.sizes ? preloadedData.sizes.split(',') : [],
        price: preloadedData.price || 0,
        quantite: preloadedData.stock || 0,
        image: null,
        urlImage: preloadedData.proImg || null,
        add_offer: !!preloadedData.offer_number,
        offer_number: preloadedData.offer_number || 0,
        offer_date_debut: preloadedData.offer_date_debut || '',
        offer_date_fin: preloadedData.offer_date_fin || '',
      });
      fetchProducts();

    } catch (error) {
      toast.warning('Oups !! Une erreur de récupération des données');
    }
  }

  useEffect(() => {
    if (id) {
      fetchUpdateProduct();
    } else {
      fetchProducts();
    }
  }, []);

  // Fonction pour ajouter des couleurs sélectionnées
  const handleAddColor = () => {
    if (selectedColor && !forms.color.includes(selectedColor)) {
      setForms((prevForms) => ({
        ...prevForms,
        color: [...prevForms.color, selectedColor],
      }));
      setSelectedColor('');
    }
  };

  // Fonction pour ajouter des tailles sélectionnées
  const handleAddSize = () => {
    if (selectedSize && !forms.size.includes(selectedSize)) {
      setForms((prevForms) => ({
        ...prevForms,
        size: [...prevForms.size, selectedSize],
      }));
      setSelectedSize('');
    }
  };

  // Fonction pour gérer le changement d'image
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setForms((prevData) => ({
        ...prevData,
        image: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearDataForm = () => {
    setForms({
      pname: '',
      desc: '',
      categorie: '',
      color: [],
      qualite: '',
      size: [],
      price: 0,
      quantite: 0,
      image: null,
      add_offer: false,
      offer_number: 0,
      offer_date_debut: '',
      offer_date_fin: '',
    });
    setSelectedColor('');
    setSelectedSize('');
    setImagePreview(null);
    setErrorValidate({
      pname: '',
      desc: '',
      categorie: '',
      color: '',
      qualite: '',
      size: '',
      price: '',
      quantite: '',
      image: null,
      urlImage: null,
      add_offer: false,
      offer_number: '',
      offer_date_debut: '',
      offer_date_fin: '',
    });
  }

  // Fonction d'envoi des données
  const handleSubmitData = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const url = id ? `${URL_API}/admin/update-products/${id}` : `${URL_API}/admin/store-products`;

      const data = id
        ? { ...forms, id }
        : forms;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.status === 400) {
        setErrorValidate(response.data.errors);
        toast.error("Oups !! erreur.s de validation des données");
      }

      if (response.data.status === 200) {
        toast.success(response.data.message);
        if (id) {
          navigate('/pages/produits')
        } else {
          clearDataForm();
        }
      }

      if (response.data.status === 401) {
        toast.info('Une error de modification des données')
      }


    } catch (error) {
      toast.error('Erreur lors de l\'ajout du produit');
    }
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, type, value } = e.target;

    // Vérification si l'élément est un input pour le champ de case à cocher
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked; // Cast pour accéder à la propriété checked
      setForms({
        ...forms,
        [name]: checked,
      });
    } else {
      setForms({
        ...forms,
        [name]: value,
      });
    }
  };

  return (
    <>
      <Breadcrumbs mainTitle={AddProduct} parent="Products" />
      <Container fluid className="add-product">
        <Form onSubmit={handleSubmitData}>
          <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  <div className="product-info">
                    <H4>{Description}</H4>
                    <div className="product-group">
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label>{ProductName}</Label>
                            <Input
                              placeholder={EnterProductName}
                              name='pname'
                              value={forms.pname}
                              onChange={changeHandler}
                              type="text"
                            />
                            {errorValidate.pname && <span className="text-danger">{errorValidate.pname}</span>}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label>{ProductDescription}</Label>
                            <textarea
                              className="form-control"
                              name="desc"
                              value={forms.desc}
                              onChange={changeHandler}
                              placeholder={EnterProductDescription}
                              rows={5}
                            />
                            {errorValidate.desc && <span className="text-danger">{errorValidate.desc}</span>}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <H4 className="mt-4">{CategoriesLearning}</H4>
                    <div className="product-group">
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label>{ProductCategory}</Label>
                            <Input
                              type="select"
                              name="categorie"
                              value={forms.categorie}
                              onChange={changeHandler}
                            >
                              <option value="">Select..</option>
                              {cate.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                              ))}
                            </Input>
                            {errorValidate.categorie && <span className="text-danger">{errorValidate.categorie}</span>}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {
                          id == null ? (<Col sm="12">
                            <FormGroup>
                              <Label>{Color}
                                {/* ({
                                  forms.color ? (
                                    forms.color.map((item) => (
                                      item + ","
                                      // <Btn style={{ backgroundColor: item }} className="btn-sm mx-1">
                                      // </Btn>
                                    ))
                                  ) : null
                                }) */}
                              </Label>
                              <div className="d-flex">
                                <Input
                                  type="select"
                                  name="color"
                                  value={selectedColor}
                                  onChange={(e) => setSelectedColor(e.target.value)}
                                >
                                  <option value="">Select..</option>
                                  {color.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                  ))}
                                </Input>
                                <Btn color="primary" className="ms-3 me-3 w-50" onClick={handleAddColor}>
                                  Valider le choix
                                </Btn>
                              </div>
                              {errorValidate.color && <span className="text-danger">{errorValidate.color}</span>}
                            </FormGroup>
                          </Col>) : null
                        }

                        <Col sm="12">
                          <FormGroup>
                            <Label>{Quality}</Label>
                            <Input
                              type="select"
                              name="qualite"
                              value={forms.qualite}
                              onChange={changeHandler}
                            >
                              <option value="">Select..</option>
                              <option value="Nouveau">Nouveau</option>
                              <option value="Seconde Main">Seconde Main</option>
                            </Input>
                            {errorValidate.qualite && <span className="text-danger">{errorValidate.qualite}</span>}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  <div className="product-info">
                    <H4>{ProductImageHeading}</H4>
                    <div className="product-group">
                      <div className="text-center">
                        <img
                          src={imagePreview ? imagePreview : forms.urlImage ? forms.urlImage : forms.image ? forms.image : dynamicImage('search-not-found.png')}
                          // src={imagePreview || forms.image || dynamicImage('search-not-found.png')}
                          alt="Preview"
                          style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                        />
                      </div>
                      <Input type="file" name="image" accept="image/*" onChange={handleImageChange} />
                      {errorValidate.image && <span className="text-danger">{errorValidate.image}</span>}
                    </div>
                    {
                      id == null ? (<H4 className="mt-4">Size & Price</H4>) : (<H4 className="mt-4">Price</H4>)
                    }

                    <div className="product-group">
                      {
                        id == null ? (<Row>
                          <Col sm="12">
                            <FormGroup>
                              <Label>{Size}
                                {/* (
                                {
                                  forms.size ? (
                                    forms.size
                                  ) : null
                                }) */}
                              </Label>
                              <div className="d-flex">
                                <Input type="select" name="color" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
                                  <option>Select..</option>
                                  {
                                    size.map((item) => (
                                      <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                  }
                                </Input>
                                <Btn color="primary" className="ms-3 me-3 w-50" onClick={handleAddSize}>
                                  Valider le choix
                                </Btn>
                              </div>
                              {errorValidate.size && <span className="text-danger">{errorValidate.size}</span>}
                            </FormGroup>
                          </Col>
                        </Row>) : null
                      }

                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{Price}</Label>
                            <Input type="number" name="price" value={forms.price} onChange={(e) => changeHandler(e)} />
                            {errorValidate.price && <span className="text-danger">{errorValidate.price}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{Quantity}</Label>
                            <Input type="number" name="quantite" value={forms.quantite} onChange={(e) => changeHandler(e)} />
                            {errorValidate.quantite && <span className="text-danger">{errorValidate.quantite}</span>}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {
                    id == null ? (
                      <>
                        <div className="m-3">
                          <Label for='checkbox-primary-1 fw-bold' className="d-block" check>
                            <Input
                              name="add_offer"
                              onChange={changeHandler} // Utilisez onChange au lieu de onClick
                              checked={forms.add_offer}
                              id="checkbox-primary-1"
                              color='primary'
                              className='checkbox-primary checkbox-border-primary'
                              type="checkbox"
                            />
                            Voulez-vous ajouter une offre sur ce produit ?
                          </Label>
                          {errorValidate.add_offer && <span className="text-danger">{errorValidate.add_offer}</span>}
                        </div>
                        <div className="product-info">
                          {forms.add_offer && (
                            <div className="product-group my-3">
                              <Row>
                                <Col sm="12">
                                  <FormGroup>
                                    <Label>Offer (%)</Label>
                                    <Input
                                      type="number"
                                      name="offer_number"
                                      value={forms.offer_number}
                                      onChange={changeHandler}
                                    />
                                    {errorValidate.offer_number && <span className="text-danger">{errorValidate.offer_number}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="6">
                                  <FormGroup>
                                    <Label>Date debut</Label>
                                    <Input
                                      type="date"
                                      name="offer_date_debut"
                                      value={forms.offer_date_debut}
                                      onChange={changeHandler}
                                    />
                                    {errorValidate.offer_date_debut && <span className="text-danger">{errorValidate.offer_date_debut}</span>}
                                  </FormGroup>
                                </Col>
                                <Col sm="6">
                                  <FormGroup>
                                    <Label>Date Fin</Label>
                                    <Input
                                      type="date"
                                      name="offer_date_fin"
                                      value={forms.offer_date_fin}
                                      onChange={changeHandler}
                                    />
                                    {errorValidate.offer_date_fin && <span className="text-danger">{errorValidate.offer_date_fin}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>

                          )}
                        </div>
                      </>
                    ) : null
                  }
                  <div className="text-end">
                    <Btn color="primary" type="submit">
                      {id == null ? "Ajouter le produit" : "Sauvegarder"}
                    </Btn>
                    <Link to="/pages/produits" className="btn btn-danger ms-3">
                      {id == null ? "Quitter" : "Annuler"}
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default AddProductContainer;