import { Container, Card, CardBody, Table } from "reactstrap"
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs"
import { InvoiceTwo, Invoice, ContactNo, Email, Website, InvoiceNo, PaymentStatus, TotalAmount, Date, BillingAddress, Phone, ShippingAddress, ShippingRate, Subtotal, Vat } from "../../utils/Constant"
import { dynamicImage } from "../../Service";
import { H4, Image, LI, UL } from "../../AbstractElements";
import { formatDate, formatPhoneNumber, formatPrice, PrintButtons } from "../../utils/helper/helpers";
import TableHeader from "./TableHeader";
import OrderItems from "./OrderItem";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from 'react-to-print';
import { GetOrder } from "./ApiOrders";
import { useParams } from "react-router-dom";
import { OrderInterface, RouteParams } from "./InterfaceOrder";

const ViewOrder = () => {

    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const { id } = useParams();  // Récupérer l'ID de la route

    const [order, setOrder] = useState<OrderInterface | null>(null);  // Type correct pour `order`
    const [orderItems, setOrderItems] = useState<any[]>([]);  // Type des items de la commande
    const [loading, setLoading] = useState<boolean>(true);  // État de chargement

    useEffect(() => {
        const fetchOrderData = async () => {
            if (id) {  // S'assurer que l'ID existe
                const orderData = await GetOrder({ id: Number(id) });  // Conversion en number
                if (orderData) {
                    setOrder(orderData.order);  // Supposez que `order` est dans les données
                    setOrderItems(orderData.order_items);  // Supposez que `items` est dans les données
                }

                setLoading(false);  // Terminer le chargement
            }
        };

        fetchOrderData();  // Appel de la fonction asynchrone
    }, [id]);
    const totalAmount = order?.total_amount ?? 0;
    const fraisLivraison = order?.frais_livraison ?? 0;

    return (
        <div className='page-body'>
            <>
                <Breadcrumbs mainTitle="Commande" parent="Details" />
                <Container>
                    <div ref={componentRef}>

                        <Card>
                            <CardBody>
                                <Table responsive borderless className="theme-scrollbar table-wrapper">
                                    <tbody>
                                        <tr>
                                            <td className="p-0">
                                                <Table style={{ width: "100%" }} responsive>
                                                    <tbody>
                                                        <tr>
                                                            <td className="p-0" style={{ minWidth: 347, width: "30%" }}>

                                                                <Image className="for-light" src={dynamicImage("logo/logo.png")} alt="logo" />
                                                                <Image className="for-dark" src={dynamicImage("logo/dark-logo.png")} alt="logo" />
                                                                <address style={{ opacity: "0.8", width: "80%", marginTop: 10, fontStyle: "normal" }}>
                                                                    <span style={{ fontSize: 16, lineHeight: "1.5", fontWeight: 500 }} >
                                                                        1982 Harvest Lane New York, NY12210 United State
                                                                    </span>
                                                                </address>
                                                            </td>
                                                            <td className="p-0" style={{ opacity: "0.8", textAlign: "end" }}>
                                                                <span
                                                                    style={{ display: "block", lineHeight: "1.5", fontSize: 16, fontWeight: 500 }}>
                                                                    {Email} : Edmin@themesforest.com
                                                                </span>
                                                                <span style={{ display: "block", lineHeight: "1.5", fontSize: 16, fontWeight: 500 }}>
                                                                    {Website}: www.Edminthemes.com
                                                                </span>
                                                                <span style={{ display: "block", lineHeight: "1.5", fontSize: 16, fontWeight: 500 }} >
                                                                    {ContactNo} : (316) 555-0116
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-0">
                                                <Table style={{ width: "100%" }} responsive>
                                                    <tbody>
                                                        <tr style={{ display: "flex", justifyContent: "space-between", borderTop: "1px dashed rgba(82, 82, 108, 0.3)", borderBottom: "1px dashed rgba(82, 82, 108, 0.3)", padding: "25px 0" }}>
                                                            <td className="p-0" style={{ display: "flex", alignItems: "center", gap: 6 }}>
                                                                <span style={{ opacity: "0.8", fontSize: 16, fontWeight: 500 }} >
                                                                    {InvoiceNo}
                                                                </span>
                                                                <H4 style={{ margin: 0, fontWeight: 400, fontSize: 16 }}>
                                                                    #BCA-000{order?.id}
                                                                </H4>
                                                            </td>
                                                            <td className="p-0" style={{ display: "flex", alignItems: "center", gap: 6 }}>
                                                                <span style={{ opacity: "0.8", fontSize: 16, fontWeight: 500 }} >
                                                                    {Date} :
                                                                </span>
                                                                <H4 style={{ margin: 0, fontWeight: 400, fontSize: 16 }}>
                                                                    {/* {formatDate(order?.created_at)} */}
                                                                    {order?.created_at}
                                                                </H4>
                                                            </td>
                                                            <td className="p-0" style={{ display: "flex", alignItems: "center", gap: 6 }} >
                                                                <span style={{ opacity: "0.8", fontSize: 16, fontWeight: 500 }}>
                                                                    {PaymentStatus} :
                                                                </span>
                                                                {order?.status === 'paied' || order?.status === "Paied" ? (
                                                                    <H4 style={{ margin: 0, fontWeight: 400, fontSize: 16, padding: "6px 18px", backgroundColor: "rgba(84, 186, 74, 0.1)", color: "#54BA4A", borderRadius: 5 }} >
                                                                        Payée
                                                                    </H4>
                                                                ) : (
                                                                    <H4 style={{ margin: 0, fontWeight: 400, fontSize: 16, padding: "6px 18px", backgroundColor: "rgba(255, 0, 0, 0.1)", color: "#FF0000", borderRadius: 5 }} >
                                                                        En Attente
                                                                    </H4>
                                                                )}

                                                            </td>
                                                            {/* <td className="p-0" style={{ display: "flex", alignItems: "center", gap: 6 }}>
                                                            <span style={{ opacity: "0.8", fontSize: 16, fontWeight: 500, minWidth: 136 }} >
                                                                {TotalAmount} :
                                                            </span>
                                                            <H4 style={{ margin: 0, fontWeight: 400, fontSize: 16 }}>
                                                                {formatPrice(totalAmount + fraisLivraison)}
                                                            </H4>
                                                        </td> */}
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                            {/* <InvoiceTwoDetails /> */}
                                        </tr>
                                        <tr>
                                            {/* <InvoiceTwoBilling /> */}
                                            <td className="p-0">
                                                <Table responsive style={{ width: "100%" }}>
                                                    <tbody>
                                                        <tr style={{ padding: "28px 0", display: "block" }}>
                                                            <td className="p-0" style={{ width: "70%", minWidth: "304px" }}>
                                                                <span style={{ opacity: "0.8", fontSize: 16, fontWeight: 500 }}>
                                                                    {BillingAddress}
                                                                </span>
                                                                <H4 style={{ fontWeight: 400, margin: "12px 0 6px 0", fontSize: 16 }} >
                                                                    {order?.user_firstname + " " + order?.user_lastname}
                                                                </H4>
                                                                <span style={{ width: "54%", display: "block", lineHeight: "1.5", opacity: "0.8", fontSize: 16, fontWeight: 400 }} >
                                                                    {order?.user_adresse}
                                                                </span>
                                                                <span style={{ lineHeight: "2.6", opacity: "0.8", fontSize: 16, fontWeight: 400 }} >
                                                                    {Phone} : {order?.user_phone}
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td className="p-0">
                                                                <span style={{ opacity: "0.8", fontSize: 16, fontWeight: 500 }} >
                                                                    {ShippingAddress}
                                                                </span>
                                                                <H4 style={{ fontWeight: 400, margin: "12px 0 6px 0", fontSize: 16 }} >
                                                                    {order?.other_receiver_id == null ? order?.user_firstname + " " + order?.user_lastname : order?.fname2 + " " + order?.lname2}
                                                                </H4>
                                                                <span style={{ width: "95%", display: "block", lineHeight: "1.5", opacity: "0.8", fontSize: 16, fontWeight: 400 }}>
                                                                    {order?.address2 == null ? order?.user_adresse : order?.address2}
                                                                </span>
                                                                <span style={{ lineHeight: "2.6", opacity: "0.8", fontSize: 16, fontWeight: 400 }}>
                                                                    {Phone} : {order?.phone2 == null ? order?.user_phone : formatPhoneNumber(order?.phone2)}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-0">
                                                <Table responsive borderless style={{ width: "100%", borderCollapse: "separate", borderSpacing: 0, border: "1px solid rgba(82, 82, 108, 0.1)" }}>
                                                    <thead>
                                                        <tr style={{ background: "#43B9B2", borderRadius: "8px", overflow: "hidden", boxShadow: "0px 10.9412px 10.9412px rgba(82, 77, 141, 0.04), 0px 9.51387px 7.6111px rgba(82, 77, 141, 0.06), 0px 5.05275px 4.0422px rgba(82, 77, 141, 0.0484671)" }} >
                                                            <TableHeader />

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderItems.map((data, i) => (
                                                            <tr className="invoice-dark" style={{ backgroundColor: "rgba(67, 185, 178, 0.1)", boxShadow: "0px 1px 0px 0px rgba(82, 82, 108, 0.15)" }} key={i}>
                                                                <td style={{ padding: "18px 15px", display: "flex", alignItems: "left", gap: 10 }} >
                                                                    <span style={{ width: 54, height: 51, backgroundColor: "#F5F6F9", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5 }} >
                                                                        <Image src={data.product_img} alt="laptop" style={{ height: 29 }} />
                                                                    </span>
                                                                    <UL className="simple-list" style={{ padding: 0, margin: 0, listStyle: "none" }}>
                                                                        <LI className="p-0">
                                                                            <H4 style={{ fontWeight: 400, margin: "4px 0px", fontSize: 16 }} >
                                                                                {data.product_name}
                                                                            </H4>
                                                                            <span style={{ opacity: "0.8", fontSize: 14 }}>{data.code}</span>
                                                                        </LI>
                                                                    </UL>
                                                                </td>

                                                                <td style={{ padding: "18px 15px", width: "12%", textAlign: "center" }}>
                                                                    <span style={{ fontSize: 14 }}>{formatPrice(data.product_price)}</span>
                                                                </td>
                                                                <td style={{ padding: "18px 15px", textAlign: "center" }}>
                                                                    <span style={{ fontSize: 14 }}>{data.quantity}</span>
                                                                </td>
                                                                {/* <td style={{ padding: "18px 15px", width: "10%" }}>
                                                                <span style={{ fontSize: 14 }}>{data.offer_id == null ? "0 %" : data.offer_number + " %"}</span>
                                                            </td> */}
                                                                <td style={{ padding: "18px 15px", textAlign: "center" }}>
                                                                    <span style={{ fontSize: 14 }}>{formatPrice(data.product_price * data.quantity)}</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {/* <OrderItems orderItems /> */}
                                                    </tbody>
                                                </Table>
                                            </td>
                                            {/* <InvoiceTwoContent /> */}
                                        </tr>
                                        <tr>
                                            {/* <InvoiceTwoTotal /> */}
                                            <td className="p-0">
                                                <Table borderless responsive>
                                                    <tfoot>
                                                        <tr>
                                                            <td style={{ padding: "5px 24px 5px 0", paddingTop: 15, textAlign: "end" }}>
                                                                <span style={{ fontSize: 16, fontWeight: 400 }}>{Subtotal} :</span>
                                                            </td>
                                                            <td style={{ padding: "5px 0", textAlign: "left", paddingTop: 15 }}>
                                                                <span style={{ fontSize: 16 }}>{formatPrice(totalAmount)}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "5px 24px 5px 0", textAlign: "end" }}>
                                                                <span style={{ fontSize: 16, fontWeight: 400 }}>Frais de livraison :</span>
                                                            </td>
                                                            <td style={{ padding: "5px 0", textAlign: "left", paddingTop: 0 }} >
                                                                <span style={{ fontSize: 16 }}>{formatPrice(fraisLivraison)}</span>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td style={{ padding: "5px 24px 5px 0", textAlign: "end" }}>
                                                                <span style={{ fontSize: 16, fontWeight: 400 }}> {ShippingRate} : </span>
                                                            </td>
                                                            <td style={{ padding: "5px 0", textAlign: "left", paddingTop: 0 }} >
                                                                <span style={{ fontSize: 16 }}>$10.00</span>
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td style={{ padding: "12px 24px 22px 0", }}>
                                                                <span style={{ fontWeight: 600, fontSize: 20, color: "#43B9B2" }}>
                                                                    {TotalAmount} :
                                                                </span>
                                                            </td>
                                                            <td style={{ padding: "12px 24px 22px 0", textAlign: "right" }} >
                                                                <span style={{ fontWeight: 500, fontSize: 20, color: "rgba(67, 185, 178, 1)" }} >
                                                                    {formatPrice(totalAmount + fraisLivraison)}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-0">
                                                <span style={{ display: "block", background: "rgba(82, 82, 108, 0.3)", height: 1, width: "100%", marginBottom: 24 }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>

                </Container>
                <PrintButtons handlePrint={handlePrint} />
            </>
        </div>
    )

}

export default ViewOrder

