import { useEffect, useState } from "react";
import { SizeInterface } from "./InterfaceTailles";
import axios from "axios";
import { URL_API } from "../../utils/Constant";
import { toast } from "react-toastify";

const useSizeList = (refresh: boolean): SizeInterface[] => {
    const [sizes, setsizes] = useState<SizeInterface[]>([]);

    useEffect(() => {
        const fetchSizes = async () => {
            try {
                const res = await axios.get(`${URL_API}/admin/sizes`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
                    },
                });
                setsizes(res.data.sizes);
            } catch (error) {
                toast.warning('Oups !! Une erreur de recuperation des données');
            }
        };

        fetchSizes();
    }, [refresh]); // Ajouter `refresh` comme dépendance

    return sizes;
};

export default useSizeList;

export const DeleteSizes = async ({ id }: { id: number }, onSuccess: () => void) => {
    try {
        const resp = await axios.get(`${URL_API}/admin/delete-size/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`
            }
        })

        if (resp.data.status === 200) {
            toast.warning(resp.data.message)
            onSuccess();
        }

        if (resp.data.status == 401) {
            toast.info(resp.data.message)
        }

    } catch (error) {
        toast.info('Oups !! Une erreur de modification de données')
    }

}

export const ChangeStatus = async ({ id }: { id: number }, onSuccess: () => void) => {
    try {
        const resp = await axios.get(`${URL_API}/admin/change-status-size/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`
            }
        })

        if (resp.data.status === 200) {
            toast.info(resp.data.message)
            onSuccess();
        }

        if (resp.data.status == 401) {
            toast.info(resp.data.message)
        }

    } catch (error) {
        toast.info('Oups !! Une erreur de modification de données')
    }
}