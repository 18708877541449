import { useEffect, useState } from "react";
import { Token, URL_API } from "../../utils/Constant";
import axios from "axios";
import { toast } from "react-toastify";

interface TestiInterface {
    [key: string]: any;
}

const useTestiListe = (refresh: boolean): TestiInterface[] => {
    const [testi, setTesti] = useState<TestiInterface[]>([]);

    useEffect(() => {
        const fetchTesti = async () => {
            axios.get(`${URL_API}/getTestimoniale`, {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            }).then(response => {

                if (response.status == 200) {
                    setTesti(response.data.testi);
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    toast.info(error.response.data.message);
                }

                if (error.response.status === 500) {
                    toast.warning(error.response.data.message);
                }
            })
        };

        fetchTesti();
    }, [refresh]);
    return testi;
};

export default useTestiListe;

export const deleteTesit = async ({ id }: { id: number }, onSuccess: () => void) => {

    axios.get(`${URL_API}/testi-destroy/${id}`, {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    }).then(response => {

        if (response.status === 200) {
            toast.warning(response.data.message)
            onSuccess();
        }

    }).catch(error => {
        if (error.status === 405) {
            toast.info(error.response.data.message)
        }
    })

}

export const changeStatus = ({ id }: { id: number }, onSuccess: () => void) => {
    axios.get(`${URL_API}/----------/${id}`, {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    }).then(response => {

        if (response.status === 200) {
            toast.warning(response.data.message)
            onSuccess();
        }

    }).catch(error => {
        if (error.status === 405) {
            toast.info(error.response.data.message)
        }
    })
}

