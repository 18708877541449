import { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs"
import useCategorie from "./ApiCategorie";
import { CommonTableProp } from "../../Type/Tables/ReactstrapTable/BasicTable/BasicTable";
import SweetAlert from 'sweetalert2';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Btn, H1, H4, P } from "../../AbstractElements";
import { CKEditorExample, EditorsText, SearchTableButton, URL_API } from "../../utils/Constant";
import { toast } from "react-toastify";
import axios from "axios";
import { truncateText } from "../../utils/helper/helpers";
import CardHeaderCommon from "../../CommonElements/CommonCardHeader/CardHeaderCommon";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

type Categorie = { [key: string]: any }

const GetCategories = () => {
    const [filterText, setFilterText] = useState("");
    const [refresh, setRefresh] = useState(false); // État de rafraîchissement
    const cate = useCategorie(refresh) || []; // Passer l'état de rafraîchissement comme paramètre
    const [filteredItems, setFilteredItems] = useState<Categorie[]>([]);
    const [forms, setForms] = useState<Categorie>({
        name: '',
        desc: '',
        id: null
    });
    const [errorValidate, setErrorValidate] = useState({
        name: '',
        desc: '',
        id: null
    });

    const loremText: string = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at vulputate urna, sed dignissim arcu. Aliquam at ligula imperdiet, faucibus ante a, interdum enim. Sed in mauris a lectus lobortis condimentum. Sed in nunc magna. Quisque massa urna, cursus vitae commodo eget, rhoncus nec erat. Sed sapien turpis, elementum sit amet elit vitae, elementum gravida eros. In ornare tempus nibh ut lobortis. Nam venenatis justo ex, vitae vulputate neque laoreet a.`;

    const [editing, setEditing] = useState(false);
    const showEditor = () => setEditing(!editing);

    useEffect(() => {
        const filtered = cate.filter((item) =>
            item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
        );
        if (JSON.stringify(filtered) !== JSON.stringify(filteredItems)) {
            setFilteredItems(filtered);
        }
    }, [cate, filterText, filteredItems]);

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Row>
                <Col sm="6">
                    <div className="dataTables_filter d-flex align-items-center">
                        <Label className="me-2">{SearchTableButton}:</Label>
                        <Input
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                            type="search"
                            value={filterText}
                        />
                    </div>
                </Col>
            </Row>
        );
    }, [filterText]);

    const customCateHoverHead = [
        { id: 1, head: 'Id' },
        { id: 2, head: 'Categorie' },
        { id: 3, head: 'Descriptions' },
        { id: 4, head: 'Actions' },
    ];

    const CommonTable: React.FC<CommonTableProp> = ({ tableClass, strip, size, hover, headClass, headData, children }) => {
        return (
            <div className={`theme-scrollbar ${tableClass ? tableClass : ""}`}>
                <Table striped={strip} hover={hover} size={size} responsive={"sm"}>
                    <thead className={headClass}>
                        <tr>
                            {headData.map((head) => (
                                <th key={head.id} scope="col">
                                    {head.head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{children}</tbody>
                </Table>
            </div>
        );
    };

    const clearDataForm = () => {
        setForms({
            name: '',
            desc: '',
            id: null
        });

        setErrorValidate({
            name: '',
            desc: '',
            id: null
        })
    }

    const handleSubmitData = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const url = forms.id ? `${URL_API}/admin/update-categorie` : `${URL_API}/admin/store-categorie`;

            const response = await axios.post(url, forms, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
                },
            });

            if (response.data.status === 400) {
                setErrorValidate(response.data.errors);
                toast.error(response.data.message);
            }

            if (response.data.status === 200) {
                toast.success(response.data.message);
                clearDataForm();
            }

            if (response.data.status === 401) {
                toast.info(response.data.message)
            }
            setRefresh((prev) => !prev);

        } catch (error) {
            toast.error('Erreur lors de l\'ajout de la categorie');
        }
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, type, value } = e.target;
        setForms({ ...forms, [name]: value, });
    };

    const handleCKEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setForms({ ...forms, desc: data });
    };

    const editeCategorie = ({ id }: { id: number }) => {
        const cate = filteredItems.find((item) => item.id === id);
        cate ? setForms({
            name: cate.name,
            desc: cate.description,
            id: cate.id
        }) : toast.info('Cet element en figure pas dans le donnes')
    }

    return (
        <div className="page-body">
            <Breadcrumbs mainTitle='Catégories' parent='Accueil' />
            <Container fluid>
                <Row>
                    <Col sm="6">
                        <Card>
                            <CardBody>{subHeaderComponentMemo}</CardBody>
                            <CommonTable
                                strip
                                hover
                                tableClass='table table-striped bg-primary text-center'
                                headClass='tbl-strip-thad-bdr'
                                headData={customCateHoverHead}
                            >
                                {filteredItems.map((item, index) => (
                                    <tr key={item.id} className='text-center'>
                                        <th scope='row'>#{index + 1}</th>
                                        <td>
                                            <span className="fw-bold text-dark">
                                                {item.name}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="fw-bold text-dark">
                                                {truncateText(item.description, 50)}
                                            </span>
                                        </td>
                                        <td>
                                            <Btn className='bg-dark btn-xs mx-1 my-1' onClick={() => editeCategorie({ id: item.id })}>
                                                <i className='fa fa-pencil'></i>
                                            </Btn>
                                        </td>
                                    </tr>
                                ))}
                            </CommonTable>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmitData}>
                                    <H4 className="my-4">
                                        {
                                            forms.id == null ? ("Ajouter une Couleur d'arcticle") : ("Modification d'une couleur d'article")
                                        }
                                    </H4>

                                    <div className="product-info">
                                        <div className="product-group">
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Couleur</Label>
                                                        <Input
                                                            placeholder="Entrer la couleur d'article"
                                                            name="name"
                                                            value={forms.name}
                                                            onChange={changeHandler}
                                                            type="text"
                                                        />
                                                        {errorValidate.name && (
                                                            <span className="text-danger">{errorValidate.name}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Description</Label>
                                                        <textarea
                                                            className="form-control"
                                                            rows={10}
                                                            placeholder="Entrer la description de la categories"
                                                            name="desc"
                                                            value={forms.desc}
                                                            onChange={changeHandler}>



                                                        </textarea>
                                                        {errorValidate.desc && (
                                                            <span className="text-danger">{errorValidate.desc}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col sm="12">
                                                    <FormGroup>
                                                        <Label>Description</Label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={forms.desc}
                                                            name='desc'
                                                            onChange={handleCKEditorChange}
                                                        />
                                                        {errorValidate.desc && (
                                                            <span className="text-danger">{errorValidate.desc}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col> */}
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <Btn color="primary" type="submit">
                                            {forms.id == null ? "Ajouter" : "Sauvegarder"}
                                        </Btn>
                                        <Btn className="btn btn-danger ms-3" onClick={() => clearDataForm()}>
                                            Annuler
                                        </Btn>
                                    </div>
                                </Form>
                            </CardBody>

                        </Card>

                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default GetCategories