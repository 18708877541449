import { MenuItem } from "../../Type/Layout/Sidebar";

export const MenuList: MenuItem[] = [
  {
    title: "General",
    Items: [
      {
        icon: "Profile",
        id: 1,
        title: "Utilisateurs",
        path: `${process.env.PUBLIC_URL}/pages/utilisateurs`,
        type: "link",
      },
      {
        icon: "Bag",
        id: 1,
        title: "Produits",
        path: `${process.env.PUBLIC_URL}/pages/produits`,
        type: "link",
      },
      {
        icon: "Document",
        id: 1,
        title: "Commandes",
        path: `${process.env.PUBLIC_URL}/pages/orders`,
        type: "link",
      },
      {
        icon: "Setting",
        id: 2,
        active: false,
        title: "Parametrage",
        children: [
          { path: `${process.env.PUBLIC_URL}/pages/categories`, title: "Catégories", type: "link" },
          { path: `${process.env.PUBLIC_URL}/pages/colors`, title: "Couleurs", type: "link" },
          { path: `${process.env.PUBLIC_URL}/pages/sizes`, title: "Tailles", type: "link" },
        ],
      }, {
        icon: "Document",
        id: 2,
        active: false,
        title: "Pages",
        children: [
          { path: `${process.env.PUBLIC_URL}/autres/menus`, title: "Autres", type: "link" },
          { path: `${process.env.PUBLIC_URL}/autres-items`, title: "Éléments-Autres", type: "link" },
          { path: `${process.env.PUBLIC_URL}/testimoniale`, title: "Testimoniale", type: "link" },
          { path: `${process.env.PUBLIC_URL}/about`, title: "A Propos de Nous", type: "link" },
          { path: `${process.env.PUBLIC_URL}/slider`, title: "Slider", type: "link" },
        ],
      },
      // {
      //   icon: "Paper-plus",
      //   id: 2,
      //   active: false,
      //   title: "Sample Page",
      //   children: [{ path: `${process.env.PUBLIC_URL}/pages/samplepage`, title: "Sample Page", type: "link" }],
      // },
      {
        icon: "Ticket",
        id: 3,
        title: "Support Ticket",
        path: `https://support.pixelstrap.com/`,
        type: "link",
      },
    ],
  },
];
