import { useEffect, useState } from "react";
import { Token, URL_API } from "../../utils/Constant";
import axios from "axios";
import { toast } from "react-toastify";

interface ItemsAutresIntface {
    [key: string]: any;
}

const useItemsAutresListe = (refresh: boolean): ItemsAutresIntface[] => {
    const [itemsAutres, setItemsAutres] = useState<ItemsAutresIntface[]>([]);

    useEffect(() => {
        const fetchItemsAutres = async () => {
            axios.get(`${URL_API}/items-autres-index`, {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            }).then(response => {

                if (response.status == 200) {
                    setItemsAutres(response.data.autres);
                }

            }).catch(error => {
                if (error.status === 400) {
                    toast.info(error.data.message);
                }

                if (error.status === 500) {
                    toast.warning(error.data.message);
                }
            })
        };

        fetchItemsAutres();
    }, [refresh]);
    return itemsAutres;
};

export default useItemsAutresListe;

export const deleteUsers = async ({ id }: { id: number }, onSuccess: () => void) => {

    axios.get(`${URL_API}/admin/destroy/${id}`, {
        headers: {
            Authorization: `Bearer ${Token}`,
        }
    }).then(response => {

        if (response.status === 200) {
            toast.warning(response.data.message)
            onSuccess();
        }

    }).catch(error => {
        if (error.status === 405) {
            toast.info(error.response.data.message)
        }
    })

}

export const changeStatus = ({ id }: { id: number }, onSuccess: () => void) => {
    axios.get(`${URL_API}/admin/changeStatus/${id}`, {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    }).then(response => {

        if (response.status === 200) {
            toast.warning(response.data.message)
            onSuccess();
        }

    }).catch(error => {
        if (error.status === 405) {
            toast.info(error.response.data.message)
        }
    })
}

