import { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Link } from "react-feather";
import { Row, Col, Card, Table, Label, Input, Container, CardBody } from "reactstrap";
import { Btn } from "../../AbstractElements";
import SweetAlert from 'sweetalert2';
import useOrderList, { OrderPayed } from "./ApiOrders";
import { ItemDescription, SearchTableButton } from "../../utils/Constant";
import { CommonTableProp } from "../../Type/Tables/ReactstrapTable/BasicTable/BasicTable";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../utils/helper/helpers";

type Orders = { [key: string]: any }

const GetOrders = () => {
    const [filterText, setFilterText] = useState("");
    const [refresh, setRefresh] = useState(false); // État de rafraîchissement
    const orders = useOrderList(refresh) || []; // Passer l'état de rafraîchissement comme paramètre
    const [filteredItems, setFilteredItems] = useState<Orders[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const filtered = orders.filter((item) =>
            item.user_firstname && item.user_firstname.toLowerCase().includes(filterText.toLowerCase())
        );
        if (JSON.stringify(filtered) !== JSON.stringify(filteredItems)) {
            setFilteredItems(filtered);
        }
    }, [orders, filterText, filteredItems]);

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Row>
                <Col sm="6">
                    <div className="dataTables_filter d-flex align-items-center">
                        <Label className="me-2">{SearchTableButton}:</Label>
                        <Input
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                            type="search"
                            value={filterText}
                        />
                    </div>
                </Col>
                <Col sm="6" className='text-end'>
                    <Link to={`${process.env.PUBLIC_URL}/pages/Add-Product`}>
                        <Btn color="primary" size="sm">
                            Ajouter
                        </Btn>
                    </Link>
                </Col>
            </Row>
        );
    }, [filterText]);

    const customColorHoverHead = [
        { id: 1, head: 'Id' },
        { id: 2, head: 'Client' },
        { id: 4, head: 'Prix Total' },
        { id: 5, head: 'Status' },
        { id: 6, head: 'Receiver' },
        { id: 7, head: 'Adresse de livraison' },
        { id: 8, head: 'Actions' },
    ];

    const CommonTable: React.FC<CommonTableProp> = ({ tableClass, strip, size, hover, headClass, headData, children }) => {
        return (
            <div className={`theme-scrollbar ${tableClass ? tableClass : ""}`}>
                <Table striped={strip} hover={hover} size={size} responsive={"sm"}>
                    <thead className={headClass}>
                        <tr>
                            {headData.map((head) => (
                                <th key={head.id} scope="col">
                                    {head.head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{children}</tbody>
                </Table>
            </div>
        );
    };

    const Confirmation = ({ id }: { id: number }) => {
        SweetAlert.fire({
            title: 'Êtes-vous sûr?',
            text: "Que cette commande a été payée ??",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, c\'est payéé !'
        }).then((result) => {
            if (result.isConfirmed) {
                OrderPayed({ id: id }, () => setRefresh(!refresh)); // Passer une fonction de rappel pour mettre à jour l'état de rafraîchissement
            }
        });
    };

    const ViewOrder = ({ id }: { id: number }) => {
        navigate(`${process.env.PUBLIC_URL}/pages/view-order/${id}`)
    }

    return (
        <div className='page-body'>
            <Breadcrumbs mainTitle='Commandes' parent='Accueil' />
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>{subHeaderComponentMemo}</CardBody>
                            <CommonTable
                                strip
                                hover
                                tableClass='table table-striped bg-primary text-center'
                                headClass='tbl-strip-thad-bdr'
                                headData={customColorHoverHead}
                            >
                                {filteredItems.map((item, index) => (
                                    <tr key={item.id} className='text-center'>
                                        <th scope='row'>#{index + 1}</th>
                                        <td>
                                            {item.user_firstname + ' ' + item.user_lastname}
                                        </td>
                                        <td>
                                            <span className="text-dark fw-bold">{formatPrice(item.total_amount + item.frais_livraison)}</span> <br />
                                            <span>({item.order_items_count > 1 ? item.order_items_count + " Produits" : item.order_items_count + " Produit"})</span>
                                        </td>
                                        <td>
                                            {item.status == 'waiting' ? (
                                                <>
                                                    <span className="text-dark fw-bold">{item.methode_paiement == 'delivred' ? 'Payer a la livraison' : 'Payer Cash'}</span> <br />
                                                    <span className='text-danger text-uppercase'>{item.status}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="text-dark fw-bold">{item.methode_paiement == 'delivred' ? 'Payer a la livraison' : 'Payer Cash'}</span> <br />
                                                    <span className='text-white text-uppercase'>{item.status}</span>
                                                </>
                                            )}
                                        </td>
                                        <td>{item.other_receiver_id ? (
                                            <>
                                                <span className="text-uppercase fw-bold">
                                                    Autres
                                                </span> <br />
                                                <span className="text-dark">
                                                    {item.created_at}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="text-uppercase fw-bold">
                                                    Client
                                                </span> <br />
                                                <span className="text-dark">
                                                    {item.created_at}
                                                </span>
                                            </>
                                        )}</td>
                                        <td>{item.other_receiver_id == null ? item.user_adresse : item.address2}</td>
                                        <td>
                                            {item.status == 'waiting' ? (
                                                <>
                                                    <Btn className='bg-warning btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id })}>
                                                        Payer ?
                                                    </Btn>
                                                </>
                                            ) : (
                                                <>
                                                    <Btn className='bg-success btn-xs mx-1 my-1'>
                                                        <i className='fa fa-check'></i>
                                                    </Btn>
                                                </>
                                            )}

                                            <Btn className='bg-dark btn-xs mx-1 my-1' onClick={() => ViewOrder({ id: item.id })}>
                                                <i className='fa fa-eye'></i>
                                            </Btn>
                                        </td>
                                    </tr>
                                ))}
                            </CommonTable>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default GetOrders;