import React from 'react';
import { Link } from "react-router-dom";
import { Href, PrintInvoice, Cancel, Close, SaveChanges } from "../Constant";
import { CommonModalType } from '../../Type/Ui-Kits/UiKitsTypes';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Btn, H1, H4, H5 } from '../../AbstractElements';

interface InvoicePrintType {
    handlePrint: () => void;
}

export const PrintButtons: React.FC<InvoicePrintType> = ({ handlePrint }) => {

    return (
        <span className="pb-5" style={{ display: "flex", justifyContent: "center", gap: 15, }}>
            <Link style={{ background: "rgba(67, 185, 178 , 0.1)", color: "rgba(67, 185, 178, 1)", borderRadius: 10, padding: "18px 27px", fontSize: 16, fontWeight: 600, outline: 0, border: 0, textDecoration: "none", }} to={`${process.env.PUBLIC_URL}/pages/orders`} download>
                <i className="fa fa-reply-all" style={{ fontSize: 13, fontWeight: "bold", marginRight: 10 }} />
                {Cancel}
            </Link>
            <Link onClick={handlePrint} style={{ background: "rgba(67, 185, 178, 1)", color: "rgba(255, 255, 255, 1)", borderRadius: 10, padding: "18px 27px", fontSize: 16, fontWeight: 600, outline: 0, border: 0, textDecoration: "none", }} to={Href}>
                <i className="fa fa-print" style={{ fontSize: 13, fontWeight: "bold", marginRight: 10 }} />
                {PrintInvoice}
            </Link>
        </span>
    );
};

const CommonModal: React.FC<CommonModalType> = ({ backdrop, centered, size, isOpen, toggle, title, onClosed, sizeTitle, fullTitle, modalBodyClassName, children }) => {
    return (
        <Modal backdrop={backdrop} centered={centered} size={size} isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            {(title || sizeTitle || fullTitle) && (
                <div className="modal-header" onClick={toggle}>
                    {title && <H5 className="f-w-600">{title}</H5>}
                    {sizeTitle && <H4>{sizeTitle}</H4>}
                    {fullTitle && <H1 className="fs-5">{fullTitle}</H1>}
                    <Btn close></Btn>
                </div>
            )}
            <ModalBody className={modalBodyClassName ? modalBodyClassName : ""}>{children}</ModalBody>
            {(title || fullTitle) && (
                <ModalFooter>
                    <Btn color="secondary" onClick={toggle}>{Close}</Btn>
                    <Btn color="primary">{SaveChanges}</Btn>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default CommonModal;


export const formatDate = (date?: Date): string => {
    if (!date) {
        return ''; // Retourne une chaîne vide si la date est undefined
    }
    return new Date(date).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};

export function formatPrice(amount?: number) {
    if (!amount) {
        return '';
    }
    const formatter = new Intl.NumberFormat('fr-GN', {
        style: 'currency',
        currency: 'GNF',
        minimumFractionDigits: 0, // Pas de décimales pour le Franc guinéen
        maximumFractionDigits: 0,
    });

    return formatter.format(amount);
}

export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 9) {
        return `+224 ${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`;
    }
    return phoneNumber;
};

export const truncateText = (text: string, maxLength: number): string => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

export const Pagination = (totalPages: number, paginate: (currentPage: number) => void, currentPage: number) => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (


        <div className="pagination-wrapper pagination-wrapper-left">
            <ul className="pg-pagination">

                {
                    currentPage === 1 ? <li>
                        <button aria-label="Previous" disabled>
                            <i className="fi ti-angle-left"></i>
                        </button>
                    </li> : <li>
                        <button onClick={() => paginate(currentPage - 1)} aria-label="Previous">
                            <i className="fi ti-angle-left"></i>
                        </button>
                    </li>
                }

                {pageNumbers.map((number) => (
                    <li key={number} className={`${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)}>
                            {number}
                        </button>
                    </li>
                ))}

                {
                    currentPage === totalPages ? <li>
                        <button aria-label="Next">
                            <i className="fi ti-angle-right"></i>
                        </button>
                    </li> : <li>
                        <button onClick={() => paginate(currentPage + 1)} aria-label="Next">
                            <i className="fi ti-angle-right"></i>
                        </button>
                    </li>
                }


            </ul>
        </div>
    );
};