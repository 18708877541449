import { Card, CardBody, Col, Container, Input, Label, Row, Table } from 'reactstrap'
import { useEffect, useMemo, useState } from 'react';
import { Ecommerce, ProductList, SearchTableButton, FormControlSizingPlaceholder, Widgets, URL_API } from '../../utils/Constant';
import Breadcrumbs from '../../CommonElements/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Btn from '../../CommonElements/Button';
import useProductList, { Product } from '../../Data/Application/Ecommerce/ProductList';
import { CommonTableProp } from '../../Type/Tables/ReactstrapTable/BasicTable/BasicTable';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { toast } from 'react-toastify';

const ProductListContainer = () => {
  const [filterText, setFilterText] = useState("");
  const products = useProductList();
  const [filteredItems, setFilteredItems] = useState<Product[]>([]);
  const Today = new Date();

  // setFilteredItems(products.filter((item) => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())));
  // const filteredItems = products.filter((item) => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()));

  useEffect(() => {
    setFilteredItems(
      products.filter((item) =>
        item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  }, [products, filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Row>
        <Col sm="6">
          <div className="dataTables_filter d-flex align-items-center">
            <Label className="me-2">{SearchTableButton}:</Label>
            <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)} type="search" value={filterText} />
          </div>
        </Col>
        <Col sm="6" className='text-end'>
          <Link to={`${process.env.PUBLIC_URL}/pages/Add-Product`} >
            <Btn color="primary" size="sm" >
              Ajouter
            </Btn>
          </Link>
        </Col>
      </Row>
    );
  }, [filterText]);

  const customColorHoverHead = [
    { id: 1, head: 'Id' },
    { id: 2, head: 'Images' },
    { id: 3, head: 'Noms' },
    { id: 4, head: 'Status' },
    { id: 5, head: 'Prix (FG)' },
    { id: 6, head: 'Stocks' },
    { id: 7, head: 'Coleur' },
    { id: 8, head: 'Taille' },
    { id: 9, head: 'Actions' },
  ];

  const CommonTable: React.FC<CommonTableProp> = ({ tableClass, strip, caption, size, hover, headClass, headRowClass, headData, children, Class }) => {
    return (
      <div className={`theme-scrollbar ${tableClass ? tableClass : ""}`}>
        <Table striped={strip} hover={hover} size={size} responsive={"sm"} className={Class}>
          {caption && <caption>{caption}</caption>}
          <thead className={headClass}>
            <tr className={headRowClass}>
              {headData.map((head) => (
                <th key={head.id} scope="col">
                  {head.head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </div>
    )
  }

  // Modal de confirmation pour la suppression
  const Confirmation = ({ id }: { id: number }) => {
    SweetAlert.fire({
      title: 'Êtes-vous sûr?',
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimez-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct({ id: id });
      }
    });
  };

  const deleteProduct = async ({ id }: { id: number }) => {
    try {
      const res = await axios.get(`${URL_API}/admin/destroy-products/${id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
        },
      });
      if (res.data.status === 200) {
        toast.success("Suppression effectuer avec success");
        setFilteredItems(products.filter(product => product.id !== id));
      }

    } catch (err) {
      toast.warning("Oups !! Une erreur s'est produite");
    }
  };

  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={ProductList} parent={Ecommerce} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {
                  subHeaderComponentMemo
                }
              </CardBody>
              <CommonTable
                strip
                hover
                tableClass='table table-striped bg-primary text-center'
                headClass='tbl-strip-thad-bdr'
                headData={customColorHoverHead}
              >

                {filteredItems.map((item, index) => (
                  <tr key={item.id} className='text-center'>
                    <th scope='row'>#{index + 1}</th>

                    <td> <img src={item.proImg} alt="Image produit" width={50} height={50} /> </td>
                    <td>
                      <span className='fw-bolder text-dark'>
                        {item.title}
                      </span> <br />
                      <span>
                        {item.category_name}
                      </span>
                    </td>
                    {/* <td>{item.category_name}</td> */}
                    <td>{item.stock > 5 ? <span className='text-white'> {item.status} </span> : <span className='text-danger'> {item.status} </span>}</td>
                    <td>{item.offer ? item.price : item.price}</td>
                    <td>{item.stock}</td>
                    <td className='bg-dark'>
                      {
                        item.colors.split(',').map((colorItem, index) => (
                          <Btn className='btn-xs mx-1 my-1 p-2' key={index} style={{ backgroundColor: colorItem }}>
                          </Btn>
                        ))
                      }
                      <Btn className='btn btn-primary btn-xs mx-1 my-1' key={index}>
                        <i className='fa fa-plus'></i>
                      </Btn>
                    </td>
                    <td>
                      {
                        item.sizes.split(',').map((sizeItem, index) => (
                          <Btn className='btn-xs mx-1 my-1 p-2' key={index} style={{ backgroundColor: sizeItem }}>
                            {sizeItem}
                          </Btn>
                        ))
                      }
                      <Btn className='btn btn-primary btn-xs mx-1 my-1' key={index}>
                        <i className='fa fa-plus'></i>
                      </Btn>
                    </td>
                    <td>

                      {
                        item.offer_id &&
                          new Date(item.offer_date_debut) <= Today &&
                          new Date(item.offer_date_fin) >= Today ? (
                          <button className='bg-success btn-xs mx-1 my-1 border-0' disabled>
                            {/* <i className='fa fa-pencil-square-o'></i> */}
                            Promo Actif
                          </button>
                        ) : (
                          <Btn className='bg-info btn-xs mx-1 my-1'>
                            <i className='fa fa-add-square-o'></i>
                            Promo
                          </Btn>
                        )
                      }

                      {/* <Link to={`${process.env.PUBLIC_URL}/pages/Update-Product/${item.id}`} >
                        <Btn className='bg-info btn-xs mx-1 my-1'>
                          <i className='fa fa-pencil-square-o'></i>
                          Promo
                        </Btn>
                      </Link> */}
                      <Link to={`${process.env.PUBLIC_URL}/pages/Produits/${item.id}`} >
                        <Btn className='bg-dark btn-xs mx-1 my-1'>
                          <i className='fa fa-eye'></i>
                        </Btn>
                      </Link>

                      <Link to={`${process.env.PUBLIC_URL}/pages/Update-Product/${item.id}`} >
                        <Btn className='bg-info btn-xs mx-1 my-1'>
                          <i className='fa fa-pencil-square-o'></i>
                        </Btn>
                      </Link>

                      <Btn className='bg-danger btn-xs mx-1 my-1' onClick={() => Confirmation({ id: item.id })}>
                        <i className='fa fa-trash-o'></i>
                      </Btn>
                    </td>
                  </tr>
                ))}
              </CommonTable>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  )
}

export default ProductListContainer