import { useEffect, useState } from "react";
import { OrderInterface } from "./InterfaceOrder";
import axios from "axios";
import { URL_API } from "../../utils/Constant";
import { toast } from "react-toastify";

const useOrderList = (refresh: boolean): OrderInterface[] => {
    const [orders, setOrders] = useState<OrderInterface[]>([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const res = await axios.get(`${URL_API}/admin/orders`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
                    },
                });
                setOrders(res.data.orders);
            } catch (error) {
                toast.warning('Oups !! Une erreur de recuperation des données');
            }
        };

        fetchOrders();
    }, [refresh]); // Ajouter `refresh` comme dépendance

    return orders;
};

export default useOrderList;

const OrderPayed = async ({ id }: { id: number }, onSuccess: () => void) => {
    try {
        const res = await axios.get(`${URL_API}/admin/order-payed/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
            },
        });
        if (res.data.status === 200) {
            toast.success("Commande Payer avec success");
            onSuccess(); // Appeler la fonction de rappel pour mettre à jour l'état de rafraîchissement
        }
        if (res.data.status === 400) {
            toast.info(res.data.message);
        }
    } catch (err) {
        toast.warning("Oups !! Une erreur s'est produite");
    }
};

const GetOrder = async ({ id }: { id: number }) => {
    try {
        const response = await axios.get(`${URL_API}/order/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('login')!)}`,
            },
        });

        if (response.data.status === 200) {
            return response.data;
        } else {
            toast.info('Une erreur est survenue lors de la récupération des données de la commande');
            return null;
        }
    } catch (error) {
        toast.info('Erreur lors de la récupération des données de la commande');
        // console.error("Erreur:", error);
        return null;
    }
};


export { OrderPayed, GetOrder };