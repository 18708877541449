import { ToastContainer } from "react-toastify";
import RouterData from "./Routes";

function App() {
  return (
  <>
    <RouterData />
    <ToastContainer />
  </>
  );
}

export default App;
